.image_h_line{
    width: 90%;
    margin: auto;
    display: block;
}


.header{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2%;
}
.header_h{
    font-size: 2.5rem;
}
.s_line{
    object-fit: scale-down;
}
.p{
    text-align: center;
    font-size: 1.5rem;
    width: 50%;
    margin: 50% auto;
    margin-top: 0;
    margin-bottom: 0;
}
.buttons{
    width: max-content;
    background: var(--red);
    border-radius: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    gap: 25px;
    margin: 50% auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.button{
    border-radius: 30px;
    display: inline-flex;
    align-self: center;
    border: none;
    width: 180px;
    height: 28px;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 1px;
    color: white;
    background: rgba(96, 25, 25, 0) !important;
}
.button_active{
    border-radius: 30px;
    display: inline-flex;
    align-self: center;
    border: none;
    width: 180px;
    height: 28px;
    color: var(--red)!important;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 1px;
    background: rgb(255, 255, 255)!important;
}
.button:hover{
    color: white!important;
    background: rgb(96, 25, 25) !important;
}
#Button{
    background: white;
    color: var(--red);
}
#Button span{
    line-height: 29px;
}
.button_active span{
    line-height: 29px;
}
.content{
    display: block;
    height: auto;
    justify-content: center;
    margin: 0px auto 50px;
    width: 70%;
}
.slider{
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 50px;
}
.Select{
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 6px;
    margin: auto;
}
.Select_Li{
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    border: none;
    width: 100%;
    height: 40px;
    align-content: center;
}
.expands{
    width: 100%;
}

@media screen  and (max-width:1100px){
    .section{ padding: 10px; margin-bottom: 80px; }
    .h1{ font-size: 1.6rem; }
    .Header { margin-bottom: 0px; margin-top: 0px; }
    .content{
        width: 90%;
    }
}
@media screen  and (max-width:950px){
    .content {
        display: block;
        height: auto;
    }
    .Select {
        width: 100%;
    }
 
}

@media screen and (max-width:750px) {
    .p {
        width: 100%;
    }
}
@media screen and (max-width:680px) {
    .buttons {
        width: 70%;
    }
}
@media screen  and (max-width:580px){
    .content{
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 80px;
        height: auto;
    } 
    .Select_Li {
        font-size: 0.8rem;
    }
}
@media screen  and (max-width:500px){
    .p {
        width: 80%;
    }
}
@media screen  and (max-width:420px){
    .buttons_container {
        position: relative;
        display: flex;
        margin: 0% auto;
        justify-content: initial;
        margin-bottom: 0px;
    }
}