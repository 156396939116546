.container{
    margin: auto 10%;
    background-image: url(../../../../assets/imgs/form_bg.png);
    background-repeat: no-repeat;
    display: flex;
    padding: 15px;
    border-radius: 15px;
    background-size: cover;
    margin-bottom: 70px;
}
.form{
    background-image: url(../../../../assets/imgs/form_frame.png);
    background-repeat: no-repeat;
    padding-top: 100px;
    background-size: cover;
    position: relative;
}
.form_content{
    margin: auto 5%;
    position: relative;
    height: -webkit-fill-available;
    align-content: center;
    padding-bottom: 50px;
    display: grid;
    grid-gap: 10px;
}
.form_header{
    color: black;
    text-align: center;
    font-size: 1.2rem;
    position: relative;
    top: -30px;
    font-weight: bold;
    line-height: 25px;
    width: 80%;
    margin: auto;
}
.cursor{
    width: 30px;
    max-width: 30px;
    position: relative;
    top: 7px;
}
.field_div{

}
.field{
    height: 50px;
    border: none;
    background: #efefef;
    border-bottom: solid 1px grey;
    border-radius: 0;
    outline: none;
}
.field.ant-input:focus,.ant-input:focus-within{
    border-color: #ffffff00!important;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0)!important;
    outline: 0!important;
}

.bg_section{
    text-align: center;
    margin: 35px;
    align-self: center;
}
.section_h1{
    text-align: center;
    font-size: 3rem;
}
.section_h2{

}
.section_image{
    width: 25em!important;
    margin-top: 50px;
}
.submit_btn{
    width: 100%;
    position: relative;
    bottom: 0;
    height: 55px;
    background: #88050d;
    color: wheat;
    border-radius: 0px;
    font-weight: bold!important;
}

.submit_btn:hover{
    background: #e5bc7c!important;
    color: rgb(113, 25, 13)!important;
}


@media screen and (max-width:1100px) {
    .container{
        display: block;
        margin: auto 0%;
        border-radius: 0px;
    }
    .form{
        margin: auto 10%;
    }
    .section_image{
        width: 20em!important;
    }
    .section_h2 {
        font-size: 1.2rem;
        width: 80%;
        margin: auto;
    }
}
@media screen and (max-width:820px) {
  
    .form{
        margin: auto 5%;
    }
    .section_image{
        width: 15em!important;
    }
    .section_h2 {
        width: 100%;
    }
}