.image_h_line{
    width: 90%;
    margin: auto;
    display: block;
}
.content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: block;
    gap: 15px;
}
.header{
    margin: auto;
    width: auto;
    text-align: center;
    font-size: 5vw;
    background: linear-gradient(90deg, #d86464, #3d3d3d, #e5bc7c, #5b050a, #009688);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.img{
    margin: auto;
    font-size: 8rem;
}


@media screen and (max-width:750px){
    .header{
        font-size: 8vw;
    }
}

