/*----------------- Categories Splide ----------------- */
div#faqs_page_splide-track {
    border-radius: 8px;
    margin: 0px 66px;
    border: solid 1px #cdcdcd;
    background: #ffffff;
    padding: 8px;
    padding-left: 10px!important;
    padding-right: 10px!important;
}

div#faqs_page_splide ul.splide__pagination.splide__pagination--rtl {
    top: 10px !important;
    position: relative!important;
    display: flex !important;
  }
div#faqs_page_splide ul.splide__pagination.splide__pagination--ltr{
    top: 10px !important;
    position: relative!important;
    display: flex !important;
}
#faqs_page_splide button.splide__arrow.splide__arrow--next {
    font-size: 1.2rem;
    margin: -6px;

}
#faqs_page_splide button.splide__arrow.splide__arrow--prev {
    font-size: 1.2rem;
    margin: -6px;
}
@media screen and (max-width:800px) {
    #faqs_page_splide button.splide__arrow.splide__arrow--next {
        font-size: 0.8rem;
        margin: -12px;
        margin-top: -6px;

    }
    #faqs_page_splide button.splide__arrow.splide__arrow--prev {
        font-size: 0.8rem;
        margin: -12px;
        margin-top: -6px;
    }
    div#faqs_page_splide-track {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
    div#faqs_page_splide-track {
        padding: 5px 0px;
        margin: 0px 5px;
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
    
    #faqs_page_splide button.splide__arrow.splide__arrow--next {
        font-size: 1rem;
    }
    #faqs_page_splide button.splide__arrow.splide__arrow--prev {
        font-size: 1rem;
    }
}

@media screen and (max-width:750px) {
    #faqs_page_splide ul.splide__pagination.splide__pagination--rtl {
        top: 0px !important;
        position: relative!important;
    }
}