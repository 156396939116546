.image_h_line{
    width: 100%;
}
.header{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2%;
}
.header_h{
    font-size: 2.5rem;
}
.s_line{
    object-fit: scale-down;
}
.p{
    text-align: center;
    font-size: 1.5rem;
    width: 50%;
    margin: 50% auto;
    margin-top: 0;
    margin-bottom: 0;
}

.Content{
padding: 20px;
text-align: justify;
white-space: break-spaces;
margin-left: 8%;
margin-right: 8%;
}
.h1{
    font-size: 1.7rem;
    text-align: center;
}

@media screen and (max-width:750px){
    .h1{
    font-size: 1.2rem;
    }
    .Content{
        margin-left: 2%;
        margin-right: 2%;
        }
}