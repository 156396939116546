div#categories_splide-track {
    border-radius: 120px;
    padding: 15px;
    background: #ffffff;
    box-shadow: inset 0px 0px 11px 3px #dedede;
    padding-right: 60px !important;
    padding-left: 60px !important;
}
div#partners_splide-track {
    border-radius: 120px;
    padding: 15px;
    background: #ffffff;
    box-shadow: inset 0px 0px 11px 3px #dedede;
}
/*----------------- Header Splide ----------------- */
div#splide1 {
    border-radius: 120px;
    padding: 15px;
    background: #ffffff;
    box-shadow: inset 0px 0px 11px 3px #dedede;
}

div#splide1 button.splide__arrow.splide__arrow--next {
    font-size:.9rem;
    margin: -2px;

}
div#splide1 button.splide__arrow.splide__arrow--prev {
    font-size: .9rem;
    margin: -2px;
}
/*----------------- Categories Splide ----------------- */

div#categories_splide ul.splide__pagination.splide__pagination--rtl {
    top: 20px !important;
    position: relative!important;
    display: flex !important;

  }
div#categories_splide ul.splide__pagination.splide__pagination--ltr{
    top: 20px !important;
    position: relative!important;
    display: flex !important;
}

/*----------------- partners Splide ----------------- */

div#partners_splide ul.splide__pagination.splide__pagination--rtl {
    top: 20px !important;
    position: relative!important;
    display: flex !important;

  }
div#partners_splide ul.splide__pagination.splide__pagination--ltr{
    top: 20px !important;
    position: relative!important;
    display: flex !important;

}

div#partners_splide button.splide__arrow.splide__arrow--next{
    margin: -70px;
    margin-top: 0px;
}
div#partners_splide button.splide__arrow.splide__arrow--prev{
    margin: -70px;
    margin-top: 0px;
}
div#partners_splide ul.splide__pagination.splide__pagination--rtl {
    top: 20px !important;
    position: relative!important;
    display: flex !important;
  }
div#partners_splide ul.splide__pagination.splide__pagination--ltr{
    top: 20px !important;
    position: relative!important;
    display: flex !important;
}


/* --------------------P R I C I N G  S P L I D E ------------------- */
div#pricing_page_splide{
    width: 100%;
}
ul#pricing_page_splide-list{
    display: flex!important;
}
ul#pricing_page_splide-list{
    justify-content: center;
    padding-top: 50px!important;
}

div#pricing_page_splide button.splide__arrow.splide__arrow--next{
    margin: -70px;
    margin-top: 0px;
}
div#pricing_page_splide button.splide__arrow.splide__arrow--prev{
    margin: -70px;
    margin-top: 0px;
}
div#pricing_page_splide ul.splide__pagination.splide__pagination--rtl {
    top: 20px !important;
    position: relative!important;
    display: flex !important;
  }
div#pricing_page_splide ul.splide__pagination.splide__pagination--ltr{
    top: 20px !important;
    position: relative!important;
    display: flex !important;
}


/*----------------- News Splide ----------------- */


div#news_splide {
    max-width: 100%;
}
div#news_splide ul.splide__pagination.splide__pagination--rtl {
    top: 10px !important;
    position: relative!important;
    display: flex !important;

  }
div#news_splide ul.splide__pagination.splide__pagination--ltr{
    top: 10px !important;
    position: relative!important;
    display: flex !important;
    justify-content: center!important;
    text-align: center!important;
}

div#news_splide button.splide__arrow.splide__arrow--next{
    margin: -15px;
    margin-top: 0px;
}
div#news_splide button.splide__arrow.splide__arrow--prev{
    margin: -15px;
    margin-top: 0px;
}
/*----------------- reviews Splide ----------------- */

div#reviews_splide button.splide__arrow.splide__arrow--next{
    margin: -15px;
    margin-top: 0px;
}
div#reviews_splide button.splide__arrow.splide__arrow--prev{
    margin: -15px;
    margin-top: 0px;
}
div#reviews_splide ul.splide__pagination.splide__pagination--rtl {
    top: 20px !important;
    position: relative!important;
    display: flex !important;

  }
div#reviews_splide ul.splide__pagination.splide__pagination--ltr{
    top: 20px !important;
    position: relative!important;
    display: flex !important;

}
div#reviews_splide button.splide__pagination__page.is-active {
    color: #141414 !important;
    background-color: #000000 !important;
}
div#reviews_splide button.splide__pagination__page {
    color: #939393 !important;
    background-color: #7b7b7b !important;
}
/*----------------- pricing Splide ----------------- */

div#pricing_splide ul.splide__pagination.splide__pagination--rtl {
    top: -35px !important;
    position: relative!important;
    display: flex !important;

  }
div#pricing_splide ul.splide__pagination.splide__pagination--ltr{
    top: -35px !important;
    position: relative!important;
    display: flex !important;
}

@media screen and (max-width:1280px) {
    div#pricing_page_splide{
        width: 100%;
    }
    ul#pricing_page_splide-list{
        display: flex!important;
    }
    ul#pricing_page_splide-list{
        justify-content: inherit;
    }
    div#pricing_page_splide button.splide__arrow.splide__arrow--next{
        margin: -70px;
        margin-top: 0px;
    }
    div#pricing_page_splide button.splide__arrow.splide__arrow--prev{
        margin: -70px;
        margin-top: 0px;
    }
    div#pricing_page_splide ul.splide__pagination.splide__pagination--rtl {
        top: 20px !important;
        position: relative!important;
        display: flex !important;
      }
    div#pricing_page_splide ul.splide__pagination.splide__pagination--ltr{
        top: 20px !important;
        position: relative!important;
        display: flex !important;
    }
    
}

@media screen and (max-width:1100px) {

    div#pricing_page_splide button.splide__arrow.splide__arrow--next{
        margin: -20px;
        margin-top: 0px;
    }
    div#pricing_page_splide button.splide__arrow.splide__arrow--prev{
        margin: -20px;
        margin-top: 0px;
    }
    div#pricing_page_splide ul.splide__pagination.splide__pagination--rtl {
        top: 40px !important;
        position: relative!important;
        display: flex !important;
      }
    div#pricing_page_splide ul.splide__pagination.splide__pagination--ltr{
        top: 40px !important;
        position: relative!important;
        display: flex !important;
    }
}

@media screen and (max-width:1000px) {

    div#pricing_page_splide button.splide__arrow.splide__arrow--next{
        margin: -10px;
        margin-top: 0px;
    }
    div#pricing_page_splide button.splide__arrow.splide__arrow--prev{
        margin: -10px;
        margin-top: 0px;
    }
    div#pricing_page_splide ul.splide__pagination.splide__pagination--rtl {
        top: 40px !important;
        position: relative!important;
        display: flex !important;
      }
    div#pricing_page_splide ul.splide__pagination.splide__pagination--ltr{
        top: 40px !important;
        position: relative!important;
        display: flex !important;
    }
}


@media screen and (max-width:720px) {

    div#pricing_page_splide button.splide__arrow.splide__arrow--next{
        margin: 0px;
        margin-top: 0px;
    }
    div#pricing_page_splide button.splide__arrow.splide__arrow--prev{
        margin: 0px;
        margin-top: 0px;
    }
    div#pricing_page_splide ul.splide__pagination.splide__pagination--rtl {
        top: 40px !important;
        position: relative!important;
        display: flex !important;
      }
    div#pricing_page_splide ul.splide__pagination.splide__pagination--ltr{
        top: 40px !important;
        position: relative!important;
        display: flex !important;
    }
}

@media screen and (max-width:800px) {
    div#pricing_splide-track {
        border-radius: 120px;
        padding: 15px;
        background: #ffffff;
    }
    #categories_splide button.splide__arrow.splide__arrow--next {
        font-size: 1.2rem;
        margin: -4px;

    }
    #categories_splide button.splide__arrow.splide__arrow--prev {
        font-size: 1.2rem;
        margin: -4px;
    }
    #partners_splide button.splide__arrow.splide__arrow--next {
        font-size: 1.2rem;
        margin: -4px;

    }
    #partners_splide button.splide__arrow.splide__arrow--prev {
        font-size: 1.2rem;
        margin: -4px;
    }

    div#news_splide ul.splide__pagination.splide__pagination--rtl {
        top: -10px !important;
        position: relative!important;
    display: flex !important;

      }
    div#news_splide ul.splide__pagination.splide__pagination--ltr{
        top: -10px !important;
        position: relative!important;
    display: flex !important;

    }




}
@media screen and (max-width:750px) {

    #categories_splide ul.splide__pagination.splide__pagination--rtl {
        top: 0px !important;
        position: relative!important;
    display: flex !important;

    }
    #partners_splide ul.splide__pagination.splide__pagination--rtl {
        top: 0px !important;
        position: relative!important;
    display: flex !important;

    }

    div#partners_splide button.splide__arrow.splide__arrow--next{
        margin: -10px;
        margin-top: 0px;
        display: block!important;
    }
    div#partners_splide button.splide__arrow.splide__arrow--prev{
        margin: -10px;
        margin-top: 0px;
        display: block!important;
    }
}
@media screen and (max-width:500px) {
    #categories_splide button.splide__arrow.splide__arrow--next {
        font-size: 1rem;
    }
    #categories_splide button.splide__arrow.splide__arrow--prev {
        font-size: 1rem;
    }
    #partners_splide button.splide__arrow.splide__arrow--next {
        font-size: 1rem;
    }
    #partners_splide button.splide__arrow.splide__arrow--prev {
        font-size: 1rem;
    }
}
@media screen and (max-width:500px) {
    div#categories_page_splide-track {
        padding: 5px 0px;
        margin: 0px 5px;
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
    div#partners_splide-track {
        padding: 5px 0px;
        margin: 0px 5px;
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
    div#partners_splide button.splide__arrow.splide__arrow--next{
        margin: -10px;
        margin-top: -5px;
        display: block!important;
    }
    div#partners_splide button.splide__arrow.splide__arrow--prev{
        margin: -10px;
        margin-top: -5px;
        display: block!important;
    }
    div#news_splide ul.splide__pagination.splide__pagination--rtl {
        top: 20px !important;
        position: relative!important;
        display: flex !important;

      }
    div#news_splide ul.splide__pagination.splide__pagination--ltr{
        top: 20px !important;
        position: relative!important;
        display: flex !important;
    }
}

@media screen and (max-width:460px) {
    div#pricing_splide button.splide__arrow.splide__arrow--next{
        margin: -15px;
        margin-top: 0px;
    }
    div#pricing_splide button.splide__arrow.splide__arrow--prev{
        margin: -15px;
        margin-top: 0px;
    }
}
