.drawer_icon{
    display: none!important;
    position: fixed!important;
    right: 20px;
    font-size: 1.45rem;
    bottom: 40px;
    height: 47px;
    width: 47px;
    border-radius: 50px;
    background: rgb(255, 255, 255);
    color: black;
    padding: 0;
    align-items: center;
    justify-content: center;
    z-index: 99;
    box-shadow: 0px 0px 8px 2px #42424269;
    cursor: auto;
    
}

.drawer_icon:hover{
    color: black!important;
    border: solid 1px grey!important;
}
.header{
    display: flex;
    width: calc(100% - 20px);
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    color: rgb(255, 255, 255);
    background: linear-gradient(45deg, #411010, #624848);
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1rem;
}
.li{
    padding: 10px;
    width: 100%;
    border-bottom: solid 1px rgb(200, 200, 200);
    border-left: solid 1px #cfcfcf;
    text-align: center;
    font-size: 1.2rem;
    cursor: pointer;
    justify-content: start;
    display: flex!important;
    background: #e4e4e4;
    align-items: center;
    background: white;
    color: #575757;
}
.li_info{
    padding: 10px;
    width: 100%;
    border-bottom: solid 1px rgb(231 227 227);
    border-left: solid 1px rgb(231 227 227);
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    justify-content: start;
    display: flex!important;
    align-items: center;
    background: #edededb5;
    color: rgb(58, 58, 58);
}

.active_tab{
    padding: 10px;
    width: 100%;
    border-bottom: solid 3px var(--red);
    border-left: solid 1px #727272;
    text-align: center;
    font-size: 1.2rem;
    cursor: pointer;
    justify-content: start;
    display: flex!important;
    background: #e4e4e4;
    color: var(--red);
    align-items: center;
}
.text{
    font-size: 0.9rem;
    font-weight: bold;
    margin: auto 4px;
}
.text_info{
    font-size: 0.8rem;
    font-weight: bold;
    margin: auto 4px;
}
.icon{
    font-size: 1.5rem;
}
.sustainabilty_image{
    width: 30%;
    filter: invert(1);
    margin: auto 30%;
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}

@media screen and (max-width:1100px) {
    .drawer_icon{
        display: flex!important;
    }
}

@media screen and (max-width:700px) {
    .sustainabilty_image{
        width: 50%;
        margin: auto 25%;
        margin-top: 15%!important;
        margin-bottom: 15%!important;
    }
}
