.section{
    text-align: center;
    text-align: -webkit-center;
    justify-content: center;
    background: white;
    background: url(../../../../assets/imgs/coverf.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    padding-top: 0;
    filter: saturate(1.38);
    margin-bottom: 50px;
}

.Card{
    display: inline-flex;
    background: white;
    border-radius: 20px;
    display: block;
}

.username{
    position: relative;
    bottom: 0px;
    font-weight: bolder;
    color: #88050d;
    padding: 5px;
    background: #eaeaea;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 10px;
}

.comment{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 20px;
    padding-bottom: 10px;
    min-height: 10em;
    max-height: 10em;
    align-content: center;
}

@media screen and (max-width:1100px) {
    .section{
        margin-bottom: 0px;
    }
}
@media screen and (max-width:750px) {
    .section{
        padding: 0px;
        padding-bottom: 40px;
    }
    .Card{
        margin-left: 10%;
        margin-right: 10%;
    }
}
@media screen and (max-width:700px) {
    .username{
        position: relative;
        width: calc(100% - 20px);
    }
}
@media screen and (max-width:400px) {
    .comment {
        padding-top: 40px;
        padding-bottom: 30px;
        min-height: 12em;
        max-height: 12em;
    }
}
