
.container{
    text-align: center;
    margin: auto 10%;
}
.image_h_line{
    width: 90%;
    margin: auto;
    display: block;
}
.slider{
    margin-top: 20px;
    margin-bottom: -10px;
}
.circle{
    position: relative;
    text-align: center;
    justify-content: center;
    display: flex;
    align-content: center;
    cursor: pointer;
}
.imgActive{
    min-height: 110px;
    max-height: 110px;
    min-height: 110px;
    max-height: 110px;
    border: solid 3px #009688;
    border-radius: 70px;
    box-shadow: 0px 0px 8px #009688;
}

.circle_image{
    min-height: 110px;
    max-height: 110px;
    height: 110px!important;
    width: 110px!important;
    object-fit: scale-down;
}

.label{
    position: relative;
    color: #5b5b5b;
    width: 80px;
    padding: 8px;
    align-content: center;
    height: 30px;
    /* line-height: 12px; */
    font-size: 0.8rem;
    text-align: center;
    position: absolute;
    font-weight: bold;
    margin: 12% auto;
    align-self: center;
    display: table-caption;
    /* background-color: red; */
}

.category_content{
    margin-top: 50px;
}
.main_description{
    
}
.subCategories_container{
    min-height: 100vh;
}
.skeleton_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
.sub_cards{
    padding: 40px;
}
.sub_card{
    box-shadow: 0px 0px 8px #e4e4e4;
    border-radius: 5px;
    padding: 10px;
    min-height: 280px;
    cursor: pointer;
    transition: all 0.3s ease-in-out!important;
    filter: brightness(1);
}
.sub_card:hover{
    filter: brightness(0.8);
}

.sub_image{
    min-height: 170px;
    max-height: 140px;
    margin-bottom: 7px;
    width: 100%!important;
    object-fit: scale-down;
}
.sub_details{
    position: relative;
    width: 100%;
}
.sub_name{
    width: 100%;
    font-weight: bold;
    font-family: 'GE_SS_light'
}
.sub_description{
font-family: 'GE_SS_light'
}
.p{
    font-size: 1.3rem;
    color: #979797;
    font-family: 'GE_SS_light'
}
.main_descrption{

}
@media screen and (max-width:1100px) {
    .container{
        margin: auto 5%;
    }
    .sub_cards{
        padding: 20px;
    }
}
@media screen and (max-width:600px) {

}
@media screen and (max-width:500px) {
  
}
@media screen and (max-width:640px) {
    .circle_image{
        min-height: 100px;
        max-height: 100px;
        height: 100px!important;
        width: 100px!important;
    }
    .label{
        width: 70px;
        padding: 7px;
        /* height: 30px; */
        /* line-height: 12px; */
        font-size: 0.75rem;
    }

}
@media screen and (max-width:600px) {
    .categories_list_btn__kq9az {
        margin-top: 40px;
        margin-bottom: -40px;
        width: 15em;
        height: 40px;
        font-size: 0.8rem;
        /* box-shadow: 0px 5px 9px #c6c6c6; */
    }
}
@media screen and (max-width:570px) {
    .circle_image{
        min-height: 85px;
        max-height: 85px;
        height: 85px!important;
        width: 85px!important;
    }
    .label{
        width: 60%;
        padding: 4px;
        /* height: 20px; */
        /* line-height: 12px; */
        font-size: .55rem;
        /* line-height: inherit; */

    }

}
@media screen and (max-width:480px) {

    .sub_name {
        min-height: 35px;
        display: grid;
        align-content: center;
    }
}
@media screen and (max-width:400px) {
    .circle_image {
        min-height: 72px;
        max-height: 72px;
        height: 72px!important;
        width: 72px!important;
    }
}
@media screen and (max-width:355px) {
    .circle_image {
        min-height: 68px;
        max-height: 68px;
        height: 68px!important;
        width: 68px!important;
    }
}

