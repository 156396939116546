.section{
    display: block;
    justify-content: center;
    margin-bottom: 0px;
    margin-right: 10%;
    margin-left: 10%;
}
.cards{
    display: flex;
    justify-content: center;
    gap: 5%;
}
.card{
    border-radius: 23px;
    width: 100%;
    cursor: pointer;
    transform: translateY(0px);
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    margin-bottom: 20px;
    background: white;
    position: relative;
    overflow: hidden;
}
.card:hover{
    transform: translateY(-10px);
}
.card:hover .card_text_content .text{
    color: var(--red);
    text-decoration: underline;
}

.card_text_content{
    padding: 10px;
    /* margin-top: -25px; */
}
.date{
    font-weight: 100;
    font-size: 15px;
    margin-top: 25px;
}
.text{
    transition: all 0.3s ease-in-out;
    font-weight: bold;
    font-size: 1rem;
    display: -webkit-box;
    max-width: 200px;
    min-height: 41px;
    max-height: 41px;
    line-height: 1.3;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card_image{
    border-top-right-radius: 23px;
    border-top-left-radius: 23px;
    width: 100%;
    max-height: 200px;
    min-height: 200px;
    object-fit: scale-down;
}
  
  .cardImage {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    z-index: 0;
    box-shadow: none;
  }
  
  .cardBlur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
    backdrop-filter: blur(5px); /* Adjust blur strength as needed */
  }
  

@media screen  and (max-width:1100px){
    .section{
        margin-right: 0%;
        margin-left: 0%;
    }
    .cards{ padding: 10px; }
    .card{ width: 100%; margin-bottom: 40px; }

}
@media screen  and (max-width:500px){
    .card {
        width: 70%;
        margin-bottom: 40px;
        margin: auto;
    }
    .card{
        cursor: auto;
    }
    .card:hover{
        transform: translateY(0px);
    }
}