@font-face {
  font-family: "JosefinSans";   /*Can be any text*/
  src: local("JosefinSans"),
    url("../src/assets/fonts/JosefinSans-VariableFont_wght.ttf") format("opentype");
}
@font-face {
  font-family: "GE_SS_light";   /*Can be any text*/
  src: local("GE_SS"),
    url("../src/assets/fonts/GE SS Unique Light.otf") format("opentype");
}
@font-face {
  font-family: "GE_SS_bold";   /*Can be any text*/
  src: local("GE_SS"),
    url("../src/assets/fonts/GE_SS_Unique_Bold_5.otf") format("opentype");
}
@font-face {
  font-family: "GE_SS_2";   /*Can be any text*/
  src: local("GE_SS"),
    url("../src/assets/fonts/alfont_com_GE-SS-Two-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Icons_Social";   /*Can be any text*/
  src: local("Icons_Social"),
    url("../src/assets/fonts/Icons Social Media 8.ttf") format("opentype");
}

:root {
  --red:#88050D;
  --green: #0A806A;
  --bg:#F0F0F0;
  --orange:#DF9019;
  --paige: #E5BC7C;
  --brown: #5E5242;
  --grey: #6B6B6B;
}

.html {
  scroll-behavior: smooth;
  width: calc(100% - 0px)!important;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: calc(100% - 0px)!important;
}

.error_404_content {
  text-align: center;
  display: block;
  justify-items: center;
  height: 90vh;
  align-content: center;
}

img.error_404_image {
  width: 20%;
}


button.splide__pagination__page.is-active {
  color: var(--red)!important;
  background-color: var(--red)!important;
}

.ant-collapse.ant-collapse-icon-position-start.css-dkbvqv{
  background: transparent!important;
  border: none!important;
  border-bottom: none!important;
  border-radius: 0px!important;
  box-shadow: none!important;
}

.ant-collapse-content.ant-collapse-content-active {
  border-bottom: none!important;
  border-radius: 0px!important;
}
.ant-collapse-content.ant-collapse-content-active{
  border-bottom: none!important;
  border-radius: 0px!important;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-dkbvqv).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0px!important;
}
:where(.css-dkbvqv).ant-collapse>.ant-collapse-item:last-child, :where(.css-dkbvqv).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0px!important;
}
button.splide__arrow.splide__arrow--next {
  font-size: 1.4rem;
  background: white;
}
button.splide__arrow.splide__arrow--prev {
  font-size: 1.4rem;
  background: white;
}

.splide__arrow svg {
  fill: #84050d!important;
  height: 1.2em!important;
  width: 1.2em!important;
}
ol {
  text-align: center!important;
  justify-content: center!important;
  position: relative!important;
  top: 10px!important;
  font-size: 1.15rem!important;
  cursor: pointer!important;
}
.sell_with_us{
  text-align: left!important;
  top: 0px!important;
}

.ar_ol{
  text-align: right!important;
  top: 0px!important;
}
.en_ol{
  text-align: left!important;
  top: 0px!important;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-breadcrumb a {
  height: auto!important;
}

:where(.css-dkbvqv).ant-breadcrumb a {
  height: 100%!important;
}
.ant-collapse-content.ant-collapse-content-active {
  border-bottom: none!important;
  border-radius: 0px!important;
  border-top: none!important;
}
.ant-collapse-content.ant-collapse-content-active{
  border-bottom: none!important;
  border-radius: 0px!important;
  border-top: none!important;
}

.ant-collapse.ant-collapse-icon-position-start.css-dev-only-do-not-override-dkbvqv {
  font-family: 'GE_SS_light'!important;
}
.ant-collapse.ant-collapse-icon-position-start.css-dkbvqv{
  font-family: 'GE_SS_light'!important;
}


h1{
  font-family: 'GE_SS_bold';
}

span.lazy-load-image-background.blur.lazy-load-image-loaded {
  position: relative!important;
  width: 100%!important;
  z-index: 9!important;
}


