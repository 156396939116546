
/*----------------- News Splide ----------------- */

div#news_splide_blog {
    max-width: 100%;
}
div#news_splide_blog ul.splide__pagination.splide__pagination--rtl {
    top: 10px !important;
    position: relative!important;
    display: flex !important;

  }
div#news_splide_blog ul.splide__pagination.splide__pagination--ltr{
    top: 10px !important;
    position: relative!important;
    display: flex !important;
    justify-content: center!important;
    text-align: center!important;
}

div#news_splide_blog button.splide__arrow.splide__arrow--next{
    margin: -15px;
    margin-top: 0px;
}
div#news_splide_blog button.splide__arrow.splide__arrow--prev{
    margin: -15px;
    margin-top: 0px;
}


@media screen and (max-width:800px) {
    div#news_splide_blog ul.splide__pagination.splide__pagination--rtl {
        top: -10px !important;
        position: relative!important;
        display: flex !important;
      }
    div#news_splide_blog ul.splide__pagination.splide__pagination--ltr{
        top: -10px !important;
        position: relative!important;
        display: flex !important;

    }
}
@media screen and (max-width:500px) {
    div#news_splide_blog ul.splide__pagination.splide__pagination--rtl {
        top: 5px !important;
        position: relative!important;
        display: flex !important;
      }
    div#news_splide_blog ul.splide__pagination.splide__pagination--ltr{
        top: 5px !important;
        position: relative!important;
        display: flex !important;

    }
}