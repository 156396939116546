.image_h_line{
    width: 90%;
    margin: auto;
    display: block;
}
.cover{
    width: 80%;
    object-fit: scale-down;
    margin: auto 10%;
}
.wave{
    margin-top: -10px;
    margin-bottom: -10px;
    width: 100%;
}
.welcome{
    background: rgb(216 184 155);
    margin-top: -10px;
}
.welcome_header{
    background-image: url(../../assets/pricing/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    text-align: center;
    height: 120px;
    margin: auto;
    align-content: center;
    font-size: 2rem;
    color: var(--red);
    margin-top: 10px;
}

.welcome_h3{
    font-family: 'GE_SS_LIGHT'!important;
    text-align: center;
    color: var(--red);
    font-size: 1.9rem;
    font-weight: revert;
    width: 90%;
    margin: auto 5%;
    margin-bottom: 10px;
}
.welcome_p{
    text-align: center;
    color: var(--red);
    font-weight: 300;
    width: 80%;
    margin: auto;
    font-size: 1.5rem;
    margin-bottom: 50px;
}
.paper_header{
    width: max-content;
    text-align: center;
    margin: auto;
    align-content: center;
    color: var(--red);
    margin-top: 10px;
    font-weight: bold;
    position: relative;
    transform: scale(.9);
}
.bg{

}
.header_text{
    padding: 0;
    position: absolute;
    margin: 18% auto;
    width: 100%;
    font-size: 1.3rem;
}
.advantage_cards{
    width: 70%;
    margin: auto 15%;
}
.advantage_card{
    padding: 20px;
    display: grid;
    text-align: center;
    justify-content: center;
    color: var(--red);
    font-weight: 500;
}
.card_icon{
    width: 100%;
    object-fit: scale-down;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
}
.content{
    width: 64%;
    margin: auto 18%;
    margin-bottom: 30px;
    margin-top: 50px;
}
.pricing_content{

}

.Slider{
    
}


.header_content{

}
.header{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2%;
}
.header_h{
    font-size: 2.5rem;
}
.s_line{
    object-fit: scale-down;
}
.p{
    text-align: center;
    font-size: 1.5rem;
    width: 50%;
    margin: 50% auto;
    margin-top: 0;
    margin-bottom: 0;
}
.subP{
    text-align: center;
    font-size: 1.5rem;
    width: 50%;
    margin: 50% auto;
    margin-top: 0;
    margin-bottom: 2rem;
    color: #88050d;
    width: 600px;
    font-size: 2rem;
    font-family: JosefinSans;
    /* color: #888888; */
    font-weight: 300;
}
.section{
    text-align: center;
    text-align: -webkit-center;
    justify-content: center;
    margin: 120px;
    background: white;
    margin-top: -60px;
    margin-bottom: 50px;
}

.Header{
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 15px;
}

.buttons_container{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    cursor: pointer;
}

.buttons{
    width: max-content;
    background: var(--red);
    border-radius: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    gap: 25px;
    margin-top: 10px!important;
    margin-bottom: 30px!important;
    margin: auto;
    cursor: pointer;
}
.buttons_radio{
    width: max-content;
    border-radius: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    gap: 25px;
    margin-top: 10px!important;
    margin-bottom: 30px!important;
    margin: auto;
    cursor: pointer;
}
.radio_box{
    display: flex;
    align-self: center;
    align-items: center;
    color: #88050d;
    gap: 5px;
    cursor: pointer;
}

.radio_input {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    border: 0.24rem solid #00000066;
    border-radius: 50%;
    width: 1.35rem;
    height: 1.38rem;
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-right: 8px;
    top: -3px;
}



.radio_input:checked {
    background-color: #fff; /* Ensure the background is white */
    border-color: #8B0000; /* Dark red border color when selected */
}

.radio_input:checked::after {
    content: '';
    display: inline-block;
    width: 12px; /* Size of the inner dot */
    height: 12px;
    background-color: #8B0000; /* Dark red color for the inner dot */
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
}




.button{
    border-radius: 30px;
    display: inline-flex;
    align-self: center;
    border: none;
    width: 200px;
    height: 28px;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 1px;
    color: white;
    background: rgba(96, 25, 25, 0) !important;
    font-family: 'GE_SS_light';
    
}
.button_active{
    border-radius: 30px;
    display: inline-flex;
    align-self: center;
    border: none;
    width: 200px;
    height: 28px;
    color: var(--red)!important;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 1px;
    background: rgb(255, 255, 255)!important;
    font-family: 'GE_SS_light';
}
.button:hover{
    color: white!important;
    background: rgb(96, 25, 25) !important;
}
#Button{
    width: 200px;
    background: white;
    color: var(--red);
}
.sub_notice{
    position: relative;
    top: 15px;
}
.notice_text{
    width: 100px;
    color: var(--green);
    font-weight: 600;
    margin: 20px;
    position: relative;
}
.arrow{

}

.flex_cards{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.cards{
    display: flex;
    gap: 0px;
    width: calc(80% - 40px);
    margin-top: 20px!important;
    margin: auto calc(10% + 40px);
    margin-bottom: 10px;
    justify-content: center;
}
.popular{
    justify-content: center;
    text-align: center;
    position: absolute;
    top: -39px;
    margin: auto 22%;
    width: 50%;
    background: #88050d;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    display: flex;
    align-items: center;
}
.popular_icon{
    font-size: 1.7rem;
}
.card{
    background: white;
    border-radius: 55px;
    padding: 10px;
    width: 100%;
    border: solid 1px grey;
    transform: scale(.9);
    transition: all 0.3s ease-in-out;
}

.card_splide:hover{
    transform: scale(0.95);
}
.card:hover{
    transform: scale(0.95);
}
.packages_container{
    background: rgba(136, 5, 13, 0.04);
}
.flex_pricing_div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.main_price{
    font-size: 2rem;
    color: rgba(0, 128, 0, 1);
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}
.commisions_content{
    justify-content: space-evenly;
    display: flex;
    text-align: center;
    border-bottom: solid 1px #757575;
    padding-bottom: 10px;
}
.commisions_content_li{

}
.commisions_content_li_p{
    background: #906d29bf;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    padding-top: 13px;
    width: 120px;
    text-align: center;
    color: #ffeed5;
    justify-content: center;
}
.commisions_content_li_price{
    font-size: 1.2rem;
}
#subscribe_btn{
    border: none;
    background: rgba(0, 128, 0, 1);
    color: white;
    width: fit-content;
    white-space: wrap;
    font-family: 'GE_SS_light';
    height: 55px;
    font-size: 1.2rem;
    padding-top: 7px;
    font-weight: 500;
    line-height: 10px;
    transition: all 0.5s ease-in-out;
    margin: auto 15%;
    margin-top: 40px;
    margin-bottom: 30px;
}
#subscribe_btn:hover{
    transform: translateY(-5px);
    background: var(--green);
    box-shadow: 0px 10px 8px -4px #adadad;
    color: white;
    /* border: solid 1px var(--green); */
}
.month{
    font-size: 1.2rem;
    margin: 5px;
}

#save_btn{
    background: var(--red);
    color: white;
    border: navajowhite;
    border-radius: 50px;
}
.hash_price{
    text-decoration: line-through;
}
.card_h1{
    display: -webkit-box;
    text-transform: capitalize;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
    font-weight: 500;
}

.card_p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 20px;
    height: 30px;
    max-height: 30px;
    text-align: center;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.Card_Btn{
    border: none;
    background: transparent;
    color: var(--red);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
}
.Card_Btn:hover{
    color: black!important;
}
.Best_Btn{
    background: var(--green) !important;
}
.Best_Btn:hover{
    background: white !important;
    color: var(--green)!important;
}
.features_container{
    text-align: start;
    width: calc(100% - 20px);
    margin: auto;
    padding: 0px;
    padding-right: 10px;
    padding-left: 10px;
}
.features_container_splide{
    text-align: start;
    width: 100%;
    max-width: 350px;
    /* min-width: 350px; */
}
.feature_li{
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 1.1rem;
    font-weight: 400;
}


#Best{
    border: solid 3px var(--green);
    background: var(--paige);
    font-weight: bolder;
    transition: all 0.3s ease-in-out;
}
#Best:hover{
    transform: scale(0.95);
}
.best_selling{
    position: relative;
    top: -22px;
    background: var(--green);
    color: white;
    width: max-content;
    padding: 4px;
    border-radius: 35px;
    width: 40%;
    font-weight: bolder;
}

.arrow_vector_content{
    display: grid;
    gap: 10px;
    position: absolute;
    right: -80px;
    margin-bottom: 10px;
    text-align: justify;
}
.arrow_vector_image{
    margin: auto 70px;
    width: 35%;
}

.arrow_vector_content_relative{
    display: grid;
    gap: 10px;
    position: relative;
    text-align: start;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

@media screen and (max-width:1500px) {
    .card{
        width: calc(100% - 20px);
        transform: scale(0.75)!important;
        margin-top: -100px;
        margin-bottom: -100px;
    }
 
    #Best{
        width: calc(100% - 20px);
    }
}
@media screen and (max-width:1100px) {
    .section {
        margin: 0px;
    }
}
@media screen  and (max-width:1050px){
    .p { width: 90%; }
}
@media screen and (max-width:950px) {
    .p{    
        width: 100%;
        font-size: 2rem;
    }
    
    .cards{
        display: block;
        margin: 0px;
        margin-top: 0px;
        gap: 0px;
        width: 100%;
    }

}

@media screen and (max-width:720px) {
    .arrow_vector_content{
        display: none;
    }
    .buttons_container {
        margin-top: 30px!important;
    }
    .arrow_vector_image {
        margin: auto 70px;
        width: 25vw;
    }
    .buttons{
        margin-top: 20px!important;
        margin-bottom: 0px!important;
    }
}
@media screen and (max-width:700px) {

    .buttons {
        width: 60%;
    }
    .welcome_h3 {
        font-size: 1.5rem;
    }
    .welcome_p {
        font-size: 1.3rem;
    }
    .content {
        width: 98%;
        margin: auto 1%;
        margin-bottom: 40px;
        margin-top: 50px;
    }
    .advantage_cards {
        width: 90%;
        margin: auto 5%;
    }

}
@media screen and (max-width:500px) {
    .p{    
        width: 100%;
        font-size: 1rem;
    }
    .cover{
        width: 100%;
        object-fit: scale-down;
        margin: auto 0%;
    }
}

@media screen and (max-width:420px) {

    .notice_text{
        width: 80px;
        color: var(--green);
        font-weight: 600;
        margin: 20px;
        position: relative;
        font-size: 0.8rem;
    }
    
    .button_active {
        border-radius: 20px;
        align-self: center;
        width: 120px;
        font-size: 1rem;
        align-items: center;
    }
    .button{
        border-radius: 20px;
        align-self: center;
        width: 120px;
        font-size: 1rem;
        align-items: center;
    }
    .buttons_container {
        position: relative;
        display: flex;
        margin: 0% auto;
        margin-bottom: 0px;
    }
    .arrow {
        margin-top: -10px;
        position: relative;
        top: -10px;
    }
    .bg {
        width: 335px;
    }
 
    .feature_li {
        font-size: 1rem;
    }
}

@media screen and (max-width:380px) {
    .commisions_content_li_price {
        font-size: 0.9rem;
    }
}
