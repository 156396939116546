.section{
    text-align: center;
    text-align: -webkit-center;
    justify-content: center;
    background: rgba(246, 246, 246, 1);
    margin: 80px;
    margin-top: -60px;
}
.s_line{
    object-fit: scale-down;
}
.Header{
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 15px;
}
.h1{

}

.p{
    width: 600px;
    font-size: 2rem;
    font-weight: bolder;
}

.buttons{
    width: max-content;
    background: var(--red);
    border-radius: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    margin-top: 0px;
    margin-bottom: 30px;
    gap: 25px;
}

.button{
    border-radius: 30px;
    display: inline-flex;
    align-self: center;
    border: none;
    width: 170px;
    height: 35px;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 0px;
    color: white;
    background: rgba(96, 25, 25, 0) !important;
    align-items: center;
}
.button_active{
    border-radius: 30px;
    display: inline-flex;
    align-self: center;
    border: none;
    width: 170px;
    height: 35px;
    color: var(--red)!important;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 0px;
    background: rgb(255, 255, 255)!important;
    align-items: center;
}
.button:hover{
    color: white!important;
    background: rgb(96, 25, 25) !important;
}

#Button{
    background: white;
    color: var(--red);
}

.cards{
    display: flex;
    margin: 10px;
    margin-top: 30px;
    gap: 15px;
}
.card{
    background: white;
    border-radius: 15px;
    margin: 30px;
    width: 100%;
    padding: 10px;
    transition: all 0.2s ease-in-out!important;
    cursor: pointer;
    transform: translateY(0px)!important;
}
.card:hover{
    box-shadow: 0px 10px 8px grey;
    transform: translateY(-10px)!important;
   
}
.card_icon{
    width: 100%;
    height: 100%;
    max-height: 80px;
    object-fit: scale-down;
}
.card_h1{
    display: -webkit-box;
    overflow: hidden;
    font-size: 1.6rem;
    text-align: center;
    -webkit-box-pack: center;
    line-height: 35px;
}

.card_p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 130px;
    height: 250px;
    max-height: 300px;
    line-height: 22px;
    display: grid;
    align-items: center;
}

.Card_Btn{
    border: none;
    background: transparent;
    color: var(--red);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: end;
    gap: 5px;
    align-items: center;
}
.Card_Btn:hover{
    color: black!important;
}

@media screen and (max-width:1050px) {
    .card_h1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 1.2rem;
        line-height: 35px;
    }
}
@media screen and (max-width:1150px) {
    .section{
        margin: 0px;
        margin-bottom: 0px;
        padding-bottom: 80px;
        margin-top: -60px;
    }
  
    .card { margin: 0px; padding: 5px; }
}
@media screen and (max-width:950px) {
    .cards {
        display: flex;
        width: 80%;
        gap: 50px;
        flex-direction: column;
        margin: auto;
        margin-top: 30px;
    }
    .card_h1 {
        font-size: 1.6rem;
    }
}
@media screen and (max-width:770px) {

    .cards{
        flex-direction: column;
        cursor: auto;
    }
    .card{
        cursor: auto;
        margin-bottom: 10px;
    }
    .card:hover{
        box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
        transform: translateY(0px)!important;
    }
}
@media screen and (max-width:550px) {
    .card_h1 {
        font-size: 1.4rem;
    }
     .cards{
        margin: 0px;
        padding: 10px;
        margin-top: 30px;
        gap: 5px;
     }
}
@media screen and (max-width:400px) {
    .button_active {
        width: 130px;
    }
    .button{
        width: 130px;
    }
}