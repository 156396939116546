.whats_app_floating {
  background: #4CAF50;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  text-align: center;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
}
svg#whatsapp_icon {
  color: #ffffff;
  padding: 0;
  font-size: 30px;
  position: relative;
  z-index: 999999;
  margin-top: 6px;
}

#Whats_beats {
    animation: pulse-animation-w 2s infinite;
  }

  @keyframes pulse-animation-w {
    0% {
      box-shadow:0 0 0 0px rgba(113, 154, 18, 0.364);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(37, 53, 4, 0);
    }
  }
  
 

  @media screen and (max-width:1100px){
    .whats_app_floating {
      background: #ffffff;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      padding: 4px;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #c4c4c4;
    }
    svg#whatsapp_icon {
      color: #4CAF50;
      padding: 0;
      font-size: 1.8rem;
      position: relative;
      z-index: 99;
      margin: -10px;
      margin-left: -8px;
    }
    
    #Whats_beats {
        animation: pulse-animation-w 2s infinite;
      }
    
      @keyframes pulse-animation-w {
        0% {
          box-shadow:0 0 0 0px rgba(113, 154, 18, 0.364);
        }
        100% {
          box-shadow: 0 0 0 20px rgba(37, 53, 4, 0);
        }
      }
      
     
  }