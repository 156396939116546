.bread_active{
    color: var(--red);
}
.breadcrumb_item{
    max-width: 200px; /* Adjust the maximum width as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


@media screen and (max-width:550px) {
    #BreadcrumbTripple  li {
        font-size: 0.8rem;
        margin: 2px;
    }
}
@media screen and (max-width:380px) {
    #BreadcrumbTripple  li {
        font-size: 0.7rem;
        padding: 0;
        margin: 2px;
    }
    #BreadcrumbTripple  li span a {
        padding: 0;
    }
}