#Download_BTN{
    display: flex;
    align-items: center;
    width: max-content;
    border: solid 1px #b0b0b0;
    height: 40px;
    justify-items: center;
    background: white;
}
#Download_BTN:hover{
    color: rgb(112, 18, 18)!important;
}
.image_lang{ width: 2em; }
.phone_icon{
    font-size: 1.5rem;
    color: black;
}
.label{
    font-size: 0.85rem;
}
.menu_content{
    display: grid;
    gap: 8px;
}
.menu_content_img{
    width: 100% !important;
    height: 100px!important;
    margin: auto;
    object-fit: contain;
}


@media screen  and (max-width:580px){
    .phone_icon{
        font-size: 1.5rem;
    }
    .label{
        display: none;
    }
}



@media screen  and (max-width:375px){
    #Download_BTN{
        height: 30px;
        padding-left: 4px;
        padding-right: 4px;
    }
    .phone_icon {
        font-size: 1rem;
    }
}