.flex_btns{
    display: flex;
    width: max-content;
    gap: 12px;
    height: max-content;
    margin-bottom: 10px;
}

.button{
    display: grid!important;
    height: max-content!important;
    align-items: center!important;
    width: 100%!important;
    padding: 10px;
    justify-items: center;
    justify-content: space-between!important;
    color: #ffffff !important;
    border-radius: 10px!important;
    gap: 7px!important;
    background: #0000001f;
    border: none!important;
    font-weight: bold!important;
}

.icon_img{
    width: 100px;
    margin-bottom: -10px;
    border-radius: 5px;
    margin-top: 10px;
}

@media screen and (max-width:800px) {
    .button{
        font-size: 0.7rem!important;
    }
 
}
@media screen and (max-width:680px) {
    .icon_img{
        width: 80px;
        margin-bottom: -15px;
        border-radius: 5px;
        margin-top: 0px;
    }
    .text{
        display: none;
    }
}
@media screen and (max-width:500px) {
    .icon_img{
        width: 60px;
        margin-bottom: -15px;
        border-radius: 5px;
        margin-top: 0px;
    }
 
}