.container{
    width: 85%;
    margin: auto;
}
.logo{
    margin: auto;
    padding: 15px;
}
.image_h_line{
    width: 90%;
    margin: auto;
    display: block;
}
.header_p_content{
    margin: auto;
    width: 100%;
    text-align: center;
    display: flex;
    gap: 10px;
}
.header_p{
    width: 50%;
    padding: 50px;
    align-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
}
.s_line{
    object-fit: scale-down;
}

.Header{
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 15px;
    
}


.cards{
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 35px;
    margin-bottom: 25px;
}
.card{
    text-align: center;
    gap: 8px;
    display: flex;
    align-self: center;
    width: max-content;
    justify-content: center;
    align-items: center;
}
.flex_icon{
    width: 30px;
    height: 30px;
}
.card_header{
    align-self: center;
    width: max-content;
}
.card_p{
    align-self: center;
    text-align: start;
    font-size: 1rem;

}

.descriptions_container{
    margin: auto;
}


.h2_span_1{
    color: var(--red);
}



.hero_container{
    display: flex;
    margin-top: 10px;
}
.hero_blocks{
    display: block;
    width: 30%;
}
.card_hero {
    position: relative;
    overflow: hidden;
  }
  .card_hero {
    position: relative;
    overflow: hidden;
    border: none;
    padding: 0;
    background: rgba(0, 0, 0, 0);
    display: grid;
    margin: 8px;
  }
  

  .image {
    width: 30vw;
    height: 255px;
    object-fit: cover;
  }
  #image {
    width: 100%;
    height: 518px;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background: rgb(2 2 2 / 50%);
    pointer-events: none;
    font-weight: 300;
  }
  .p_img_overlay{
    font-size: 1.05rem;
    width: 85%;
  }
  
  .overlay h3 {
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .overlay p {
    margin: 0;
  }


.rotated_cards_container{
    background: #E5BC7C;
    height: 702px;
    padding: 45px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.rotated_cards{
    display: flex;
    gap: 65px;
    align-items: center;
    color: white;
    margin: 10% auto;
}
.rotated_card{
    position: relative;
    display: block;
    text-align: center;
    height: 412px;
    align-content: center;
    width: 100%;
    z-index: 5;
}
.corner_image{
    position: absolute;
    top: 0;
    right: 0;
    width: 64px;
    height: 64px;
    z-index: -1;
}
.rotated_p{
    width: 80%;
    margin: auto;
    line-height: 25px;
    font-weight: 300;
}

.rotated_card h3{
    width: 65%;
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url(../../assets/imgs/ss_cover.png); /* Update the path as per your folder structure */
    background-size: contain;
    background-position: center;
    padding: 20px;
    background-repeat: no-repeat;
    height: 50vw;
}

.banner_image {
    display: none; /* Hide the image element if it's used only as a background */
}

.banner_content {
    margin-top: 120px;
}

.banner h1 {
    margin-bottom: 20px;
}

.banner_h3{
    margin-bottom: 40px;
    color: white;
}



.banner_cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.banner_cards_out {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-top: 50px;
}

.banner_card {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 100%;
    background: rgb(49 49 49 / 42%);
    border: solid 1px rgb(255 255 255 / 44%);
    border-radius: 8px;
    color: white;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 70px;
    max-height: 70px;
    font-weight: 200;
}
.banner_card_out {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 100%;
    background: rgb(237 237 237 / 42%);
    border: solid 1px rgb(129 129 129 / 44%);
    border-radius: 8px;
    color: rgb(0 0 0);
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 70px;
    max-height: 70px;
    font-weight: 200;
}

.banner_card_icon {
    width: 20px;
    margin: 10px;
}
.banner_card_p{
    color: #d4d4d4;
    text-align: initial;
}
.banner_card_p_out{
    text-align: initial;
}
.flex_buttons{
    display: flex;
    gap: 5px;
    width: 80%;
    justify-content: center;
    margin: auto;
}
.banner_button{
    height: 45px!important;
    width: max-content !important;
    border: none!important;
}
.plant_button{
    background: var(--red)!important;
    color: white!important;
}
.sub_button{
    background: var(--paige)!important;
    color: black!important;
}
.awarenessHeading{
    font-weight: 700;
}

/* Responsive styles */
@media screen and (max-width:1450px) {
    .image {
        width: 30vw;
        height: 205px;
        object-fit: cover;
    }
    #image {
        height: 420px;
    }
}
@media screen and (max-width:1300px) {
    .rotated_p {
        line-height: 20px;
    }
}
@media screen and (max-width:1100px) {
    .container {
        width: 90%;
    }

    .banner_content {
        max-width: 45%;
        padding: 10px;
    }
    .image {
        height: 171px;
    }
    #image {
        height: 350px;
    }

}

@media screen and (max-width:1000px) {
    .rotated_cards_container {
        height: -webkit-fill-available;
    }
    .rotated_cards {
        display: grid;
        gap: 90px;
        padding: 22%;
        padding-top: 0;
        padding-bottom: 0;
    }
    .rotated_p {
        line-height: 40px;
    }
    .rotated_card {
        height: -webkit-fill-available;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
@media screen and (max-width:900px) {
    .banner_content {
        max-width: 60%;
        padding: 0px;
        margin-top: 0;
    }
    .banner{
        height: 50vw;
        width: 80%;
        margin: auto;
    }
}
@media screen and (max-width:880px) {
    .header_p_content {
        width: 100%;
        display: grid;
        justify-items: center;
    }
    .header_p {
        width: calc(90%  - 50px);
    }
    .cards {
        width: 90%;
        margin: auto;
        display: grid;
        justify-items: start;
        margin-top: 25px;
        
    }
    .card {
        justify-content: start;
        width: 100%;
    }
    .card_p {
        width: 100%;
    }
    .hero_container {
        display: block;
    }
    .hero_blocks {
        display: block;
        width: 100%;
    }
    .card_hero {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .image {
        height: 100%;
        width: 100%;
    }
    #image {
        height: 100%;
        width: 100%;
    }
    .banner_h3 {
        margin-bottom: -20px;
    }
    .banner_content {
        max-width: 50%;
        padding: 0px;
        margin-top: -10px;
    }
    .banner_card{
        min-height: 90px;
        max-height: 90px;
    }
}
@media screen and (max-width:800px) {
    .banner_h3 {
        margin-bottom: 20px;
        font-size: 0.97rem;
    }
    .banner_content {
        max-width: 90%;
        margin: auto;
    }
    .flex_buttons {
        width: 100%;
        margin: auto;
    }
    .banner_button {
        height: 45px!important;
        width: 100% !important;
    }
}
@media screen  and (max-width:750px){

    .Header {
        font-size: 0.8rem;
    }
    .s_line {
        object-fit: scale-down;
        width: 15%;
    }
    .rotated_cards {
        padding: 15%;
    }
}

@media screen and (max-width:600px) {
    .banner_card_out {
        min-height: 100px;
        max-height: 100px;
    }
    .cards {
        width: 90%;
        margin: auto;
        margin-top: 25px;
    }
    .card{
        font-size: 0.8rem;
        padding: 5px;
    }
    .card_header {
        font-size: 1.3rem;
    }
    .rotated_cards_container {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .rotated_p {
        line-height: 25px;
    }
    .h1{
        font-size: 1.3rem!important ;
    }
 
}
@media screen and (max-width:500px) {

    .rotated_cards {
        padding: 10%;
    }
}
@media screen and (max-width:400px) {

    .rotated_cards {
        padding: 5%;
    }
}