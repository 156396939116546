.goToTopButton{
    text-align: center;
    margin-bottom: 15px;
}
.icon{
    background: #0a806a !important;
    border: solid 2px #0a806a;
    color: #ffffff;
}
.icon:hover{
    background: rgb(125, 23, 23)!important;
    border: solid 2px rgb(125, 23, 23);
}