.section{
    justify-content: center;
    padding: 140px;
    padding-top: 0;
    padding-bottom: 50px;
}
.Header{
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 50px;
}
.s_line{
    object-fit: scale-down;
}
.h1{
    font-size: 2.2rem;
    font-weight: bolder;
    text-align: center;
}
#Button{
    height: 50px;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 30px;
    margin-bottom: -30px;
    background: var(--red);
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
#Button:hover{
    box-shadow: 0px 8px 8px grey;
    transform: translateY(-3px);
}
@media screen  and (max-width:1100px){
    .section{ padding: 10px; margin-bottom: 40px; }
    .h1{ font-size: 1.6rem; }
    .Header { margin-bottom: 0px; margin-top: 0px; }
}
@media screen  and (max-width:500px){

    .s_line{
        object-fit: scale-down;
        width: 10%;
        transform: scale(1.5);
    }
    #Button {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
    }
}