/*----------------- Categories Splide ----------------- */
div#categories_page_splide-track {
    border-radius: 120px;
    padding: 15px 0px;
    margin: 0px 66px;
    background: #ffffff;
    box-shadow: inset 0px 0px 11px 3px #dedede;
}

div#categories_page_splide ul.splide__pagination.splide__pagination--rtl {
    top: 20px !important;
    position: relative!important;
    display: block!important;
  }
div#categories_page_splide ul.splide__pagination.splide__pagination--ltr{
    top: 20px !important;
    position: relative!important;
    display: block!important;
}

@media screen and (max-width:800px) {
    #categories_page_splide button.splide__arrow.splide__arrow--next {
        font-size: 1.2rem;
        margin: -4px;

    }
    #categories_page_splide button.splide__arrow.splide__arrow--prev {
        font-size: 1.2rem;
        margin: -4px;
    }
    div#categories_page_splide-track {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
    div#categories_page_splide-track {
        padding: 5px 0px;
        margin: 0px 5px;
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
    
    #categories_page_splide button.splide__arrow.splide__arrow--next {
        font-size: 1rem;
    }
    #categories_page_splide button.splide__arrow.splide__arrow--prev {
        font-size: 1rem;
    }
}

@media screen and (max-width:750px) {
    #categories_page_splide ul.splide__pagination.splide__pagination--rtl {
        top: 0px !important;
        position: relative!important;
    }
}