svg.ant-skeleton-image-svg {
    width: 150px!important;
}
ul.ant-skeleton-paragraph {
    width: 50%!important;
    margin: auto!important;
    margin-top: 50px!important;
}
ul.ant-skeleton-paragraph li {
    width: 100%!important;
}
h3.ant-skeleton-title {
    width: 38%!important;
    margin: auto!important;
}