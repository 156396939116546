.ant-drawer-mask {
    pointer-events: fill!important;
}
.ant-drawer.ant-drawer-right .ant-drawer-open {
    pointer-events: fill!important;
}
.main_drawer .ant-drawer-header {
    padding: 0px!important;
}
.main_drawer .ant-drawer-body {
    padding: 0px!important;
    margin: 0px!important;
    margin-top: -1px!important;
    overflow-x: hidden!important;
}
.ant-drawer-content-wrapper {
    width: 60vw!important;
}