.page_container{
    padding: 140px;
    padding-top: 50px;
}
.content{

}
.header{
    text-align: start;
    font-size: 3.5rem;
    padding: 0px;
    font-weight: bolder;
    color: rgba(0, 0, 0, 1);
}
.header_p{
    width: 50%;
    height: 45vh;
    margin: 50% auto;
    margin-top: -20px;
    margin-bottom: 50px;
    font-size: 1.2rem;
    text-align: center;
    color: var(--red);
}

.statistics_cards{
    display: flex;
    justify-content: center;
    gap: 2%;
    background: linear-gradient(0deg, #e6bf80c2, transparent);
    padding-bottom: 62px;
    margin-top: -200px;
}
.statistics_card{
    padding: 30px;
    border-radius: 15px;
    width: 16%;
    font-size: 1.6rem;
    color: white;
    font-weight: bolder;
}
.card_name{
    font-size: 1.1rem;
    font-weight: lighter;
    text-align: center;
}
.card_number{
    font-size: 2.5rem;
    font-weight: bolder;
    text-align: center;
}

@media screen and (max-width:1100px){
    .page_container{
        padding: 40px;
        padding-top: 20px;
    }
}
@media screen and (max-width:600px){
    .p{font-size: 0.75rem;}
    
}

@media screen  and (max-width:800px){
    .header_p{
        width: 90%;
    }
}
@media screen  and (max-width:600px){
    .header{
        font-size: 2.2rem;
    }
}
@media screen  and (max-width:540px){
    .card_number {
        font-size: 1.5rem;
    }
    .card_name {
        font-size: 0.8rem;
        margin-bottom: -10px;
    }
}

.ant-col.ant-col-6.gutter-row.css-dev-only-do-not-override-dkbvqv {
    padding-left: 10px!important;
    padding-right: 10px!important;
}
.ant-col.ant-col-6.gutter-row.css-dkbvqv{
    padding-left: 10px!important;
    padding-right: 10px!important;
}