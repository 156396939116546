.card_description{

}
.card_image{
    width: 100%;
}

.header{
    font-size: 1rem;
    font-weight: bold;
    line-height: 22px;
    max-height: 44px;
    min-height: 44px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    padding: 4px;
}
.p{
    -webkit-line-clamp: 2;
    height: 60px;
    line-height: 22px;
    max-height: 60px;
    min-height: 64px;
}
.p_{
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: var(--red);
    gap: 5px;
    margin-top: 10px;
    cursor: pointer;
}
.p_icon{
    position: relative;
    top: 3px;
}

#LoadMore_Btn{
    width: 20%;
    height: 45px;
    background: var(--red);
    color: white;
    margin-top: 30px;
    margin-left: 40%;
    margin-right: 40%;
}
@media screen and (max-width:750px){
    #LoadMore_Btn{
        width: 50%;
        margin-top: 30px;
        margin-left: 25%;
        margin-right: 25%;
    }
}