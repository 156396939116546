.Section{
    display: flex;
    width: 100%;
}
.v_line{
    width: 6em;
    text-align: center;
    height: 25em;
}
#v_line_mt{
    margin-top: 6rem;
    width: 6em;
    text-align: center;
    height: 25em;
}

.center_body{
    width: calc(100% - 12em );
    display: block;
    text-align: center;
    margin-top: 80px;
}
.h1{
    font-size: 3.2rem;
    color: var(--red);
    font-weight: bolder;
    line-height: 0.9;
}
.h2{
    font-size: 3rem;
    color: var(--red);
    font-weight: bolder;
    line-height: 0.9;
}
.h3{
    font-size: 2.8rem;
    padding: 5px;
    font-weight: bolder;
    line-height: 0.9;
    background: linear-gradient(90deg, #000000, #3d3d3d, #e5bc7c, #be343c, #000000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.p{
    font-size: 1.2rem;
}
.main_image{
    width: 60em;
    margin-top: 20px;
}
#Button{
    background: var(--paige);
    color: var(--red);
    align-items: center;
    font-size: 1.2rem;
    padding-bottom: 0;
    height: 50px;
    display: flex;
    border-radius: 50px;
    border: solid 1px var(--red);
    cursor: pointer;
    font-weight: bolder;
    display: inline-flex;
    gap: 10px;
    width: 40%;
    text-align: center;
    justify-content: center;
}
#Button:hover{
    background: var(--red);
    color: white;
    box-shadow: 0px 8px 8px grey;
    transform: translateY(-3px);
}

.Buttons_flex{
    display: flex;
    justify-content: center;
    gap: 1%;
    margin-top: 20px;
    overflow: hidden;
    padding: 20px;
}
#Customer_Btn{
    height: 60px;
    background: white;
    color: var(--green);
    font-size: 1.1rem;
    font-weight: bolder;
    border: solid 2px var(--green);
    padding-bottom: 0;
}
#Seller_Btn{
    height: 60px;
    background: var(--green);
    color: white;
    font-size: 1.1rem;
    font-weight: bolder;
    border: none;
    padding-bottom: 0;
}
#Seller_Btn:hover{
    box-shadow: 0px 8px 8px grey;
    transform: translateY(-3px);
}
#Customer_Btn:hover{
    box-shadow: 0px 8px 8px grey;
    transform: translateY(-3px);
}

.line_data{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 2%;
}
.line_data_li{
    cursor: default;
    display: flex;
    gap: 3px;
    align-items:center;
}

.image_h_line{
    width: 100%;
    margin: 0;
    padding: 0;
    object-fit: cover;
    margin-top: 40px;
    margin-bottom: 40px;
}

.line_data_icon{
    color: var(--green);
    font-size: 1rem;
    margin-top: -3.5px;
}
.slider{
    margin-top: 30px;
    margin-bottom: -50px;
}
@media screen and (max-width:1250px) {
    .h1 { font-size: 2.5em; }
    .main_image{
        width: 40em;
    }
}
@media screen and (max-width:1100px) {
    #Button {
        width: max-content;
    }
    .v_line {
        width: 4em;
        height: 20em;
    }
    #v_line_mt{
        width: 4em;
        height: 20em;
    }
    .center_body{
        width: calc(100% - 8em );
        margin-top: 40px;
    }
}

@media screen and (max-width:980px) {
.h1 { font-size: 2em; }
.h2 { font-size: 1.8em; }
.h3 { font-size: 1.6em; }
}

@media screen and (max-width:780px) {
    .h1 { font-size: 1.6em; }
    #Button {
        width: max-content;
        font-size: 1.2rem;
        margin-bottom: 25px;
    }
    .v_line {
        width: 4em;
        height: 40vh;
    }
    #v_line_mt{
        width: 4em;
        height: 40vh;
    }
    .center_body{
        width: calc(100% - 6em );
    }
    .p { font-size: 1rem; }
    #Seller_Btn {
        height: 40px;
        font-size: .85rem;
    }
    #Customer_Btn{
        height: 40px;
        font-size: .85rem;
    }
    .line_data_li{ font-size: 0.85rem; }
    .main_image {
        width: 100%;
        margin-top: 80px;
    }
  
    .Buttons_flex {
        margin-top: 0px;
        margin-bottom: -20px;
    }
    .line_data {
        margin-top: 50px;
        margin-bottom: -50px;
    }
}
@media screen and (max-width:680px) {
    .h1 { 
        font-size: 2.5em;
        padding-left: 25px;
        padding-right: 25px;
        line-height: 30px;
    }
    .h2{
        font-size: 1.5em;
        padding-left: 25px;
        padding-right: 25px;
        line-height: 30px;
    }
    .h3 { font-size: 1.2em; }
}

@media screen and (max-width:600px) {

    #Button {
        width: max-content;
        font-size: 1rem;
        margin-bottom: 25px;
    }
    .Buttons_flex {
        margin-top: 0px;
        transform: scale(.9);
    }
    .line_data{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        gap: 2%;
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    .line_data_li{
        font-size: 1rem;
        padding: 5px;
        margin-top: 3px;
        font-weight: bolder;
        align-items: center;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .main_image{
        margin-top: 80px;
    }
    .v_line {
        width: 4em;
    }
    #v_line_mt{
        width: 4em;
    }
    .center_body{
        width: calc(100% - 8em );
    }
    .Buttons_flex{
        overflow: inherit;
    }
}
@media screen and (max-width:500px) {
    .v_line {
        width: 3em;
        height: 30vh;
    }
    #v_line_mt{
        width: 3em;
        height: 30vh;
    }
    .center_body{
        width: calc(100% - 6em );
    }
}
@media screen and (max-width:450px) {
    .v_line {
        width: 3em;
        height: 30vh;
    }
    #v_line_mt{
        width: 3em;
        height: 30vh;
    }
    .center_body{
        width: calc(100% - 6em );
    }
   #Button {
        width: max-content;
        font-size: .95rem;
        margin-bottom: 0px;
        height: 40px;
    }
    .h1{
        font-size: 2em;
        padding-left: 5px;
        padding-right: 5px;
    }
    .h2{
        font-size: 1.2em;
        padding-left: 5px;
        padding-right: 5px;
    }
    .h3{
        font-size: 0.8rem;
    }
    #Button{
        font-size: .75rem;
    }
    .line_data_li {
        font-size: 0.8rem;
        padding: 0px;
    }
    .Buttons_flex {
        display: grid;
        gap: 10%;
    }
    #Seller_Btn {
        height: 40px;
        font-size: 1rem;
    }
    #Customer_Btn{
        height: 40px;
        font-size: 1rem;
    }
}

@media screen  and (max-width:375px){
    #v_line_mt {
        width: 2em;
        height: 20vh;
    }
    .v_line {
        width: 2em;
        height: 20vh;
    }
    .center_body {
        width: calc(100% - 4em );
    }
    #Button {
        font-size: .95rem;
    }
    .h1{
        font-size: 1.8em;
        padding-left: 5px;
        padding-right: 5px;
    }
    .h2{
        font-size: 1.2em;
        padding-left: 5px;
        padding-right: 5px;
    }
    .h3{
        font-size: 0.85rem;
    }
    #Button{
        font-size: .7rem;
    }
    #Seller_Btn {
        font-size: .85rem;
        padding-left: 6px;
        padding-right: 6px;
    }
    #Customer_Btn {
        height: 40px;
        font-size: .85rem;
        padding-left: 6px;
        padding-right: 6px;
    }
}