.s_line{
    object-fit: scale-down;
}
.Header{
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 15px;
    
}

@media screen  and (max-width:750px){

    .Header {
        font-size: 0.8rem;
    }
}