.section{
    text-align: center;
    text-align: -webkit-center;
    justify-content: center;
    background: white;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 60px;
    border-radius: 145px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    
}
.img{
    /* width: 360px!important; */
    /* height: 130px!important; */
    /* border-radius: 120px!important; */
    /* object-fit: cover!important; */
}

@media screen and (max-width:750px) {
    .section{
        margin-left: 1%;
        margin-right: 1%;
        padding-left: 0;
        padding-right: 0;
    }
    .img{
        /* width: 100px!important; */
        /* height: 100px!important; */
        /* background: red; */
    }
    
}

@media screen and (max-width:590px) {
    .section{
        margin-left: 1%;
        margin-right: 1%;
        padding-left: 0;
        padding-right: 0;
       
    }
    .img{
        /* width: 80px!important; */
        object-fit: scale-down;
        /* height: 80px!important; */
        /* background: #000; */
    }
}
@media screen and (max-width:590px) {
    .img{
        /* width: 140px!important; */
        /* object-fit: scale-down; */
        /* height: 60px!important; */
       
    }
}