
/*----------------- pricing Splide ----------------- */

div#pricing_page_splide ul.splide__pagination.splide__pagination--rtl {
    top: -35px !important;
    position: relative!important;
    display: flex !important;

  }
div#pricing_page_splide ul.splide__pagination.splide__pagination--ltr{
    top: -35px !important;
    position: relative!important;
    display: flex !important;
}


@media screen and (max-width:800px) {
    div#pricing_page_splide-track {
        padding: 15px;
    }
}

@media screen and (max-width:460px) {
    div#pricing_page_splide button.splide__arrow.splide__arrow--next{
        margin: -15px;
        margin-top: 0px;
    }
    div#pricing_page_splide button.splide__arrow.splide__arrow--prev{
        margin: -15px;
        margin-top: 0px;
    }
}
