.Footer{
    min-height: 30vh;
    padding: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    background: #000;
    color: white;
}
.Content_1{
    display: flex;
    width: 100%;
    justify-content: center;
    border-bottom: solid 1px grey;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    direction: ltr;
}
.img_link{
    object-fit: scale-down;
    border: solid 3px #51504f;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    font-size: 1.2rem;
    padding: 5px;
    color: white;
    overflow: visible;
}
.img_link:hover{
    border: solid 3px #ffffff;
    color: #df9019;
}
.Social_Icons{
    gap: 2%;
    display: flex;
    justify-content: end;
}
.sec_1{
    width: 50%;
    text-align: end;
}
.sustainabilty_image{
    width: 30%;
    margin: 5%;
}
.sec_2{
    background: url(http://localhost:3000/static/media/cover.b646f58ec0e73337025d.png);
    background-repeat: no-repeat;
    width: 40%;
    justify-content: center;
    object-fit: cover;
    border-radius: 20px;
    align-self: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 1.6rem;
    background: rgba(229, 188, 124, 1);
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
}
.footer_header_content{
    text-align: center;
}
.small_heading{
    font-size: 1.5rem;
    font-weight: 500;
    color: #8a6b26;
}
.Form{
    width: 80%;
    display: inline-grid;
    padding-top: 20px;
    margin: auto 10%;
}
.input{
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 8px;
    border: none;
    margin-bottom: 10px;
    outline-style: none;
}
#Register{
    width: 100%;
    margin-right: 0%;
    margin-left: 0%;
    background: var(--red);
    color: white;
    height: 45px;
    border: none;
}
#Register:hover{
    background: rgb(107, 12, 12);
}
.Content_2{
    display: block;
    align-items: center;
    padding-top: 35px;
    width: 100%;
}
.Content_2_Routes{
    width: 100%;
    display: flex;
    padding: 10px;
    gap: 15px;
}


.Routes{
    display: flex;
    gap: 15px;
}

.Route_active{
    font-size: 1.1rem;
    color: rgb(255, 255, 255);
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.Route{
    font-size: 1.1rem;
    color: rgb(125, 125, 125);
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.Route:hover{
    color: var(--orange);
}
.addition_routes{
    display: flex;
    gap: 15px;
}

.Rights{
    color: rgba(255, 255, 255, 0.37);
    width: 100%;
    text-align: start;
    margin: 10px;
}
.error{
    font-size: 1rem;
    color: darkred;
    margin-bottom: 10px;
    font-weight: 400;
}
.contact_content{
    color: #878787;
    display: flex;
    justify-content: flex-end;
    padding: 0px;
    margin-top: 10px;
    gap: 10px;
}
.contact_li{
display: flex;
gap: 5px;
font-family: sans-serif;
}
.contact_li_icon{
    color: burlywood;
}



@media screen  and (max-width:1250px){
    .sec_2{
        width: calc(100% - 0px);
    }
    .Form {
        width: 80%;
    }
    .input{
        width: calc(100% - 0px);
    }
    #Register {
        height: 50px;
    }
    .sec_1 {
        width: 100%;
        text-align: center;
    }
    .Social_Icons {
        justify-content: center;
    }
    .contact_content{
        justify-content: center;
    }
}

@media screen  and (max-width:1120px){
    .Content_2_Routes {
        display: block;
    }
    .Routes {
        gap: 10px;
        justify-content: center;
        margin-bottom: 30px;
    }
    .addition_routes {
        display: grid;
        text-align: center;
    }
}
@media screen  and (max-width:1100px){
    .Rights{
        text-align: center;
        width: 100%;
    }
    .Content_2_Routes {
        text-align: center;
        justify-content: center;
    }
    
    .Footer{
        padding-bottom: 70px;
    }
    .Content_2 {
        display: block;
        justify-content: center;
        text-align: center;
    }
  
    .Route {
        font-size: 1.3rem;
        text-decoration: none;
        cursor: pointer;
    }
}
@media screen  and (max-width:980px){
    .Content_1{display: block;}
}
@media screen  and (max-width:735px){
    .Route {
        font-size: 0.95rem;
    }
    .sustainabilty_image{
        width: 45%;
        margin: 5%;
    }
}
@media screen  and (max-width:700px){
    .Content_2_Routes {
        display: grid;
        grid-gap: 10px;
    }
    .Route{
        cursor: auto;
    }
}
@media screen  and (max-width:580px){
    .small_heading{
        font-size: 0.9rem;
    }
    
    .Footer{
        padding-top: 20px;
    }
    .sec_2{
        font-size: 1rem;
        padding-top: 15px;
        padding-bottom: 15px;
        width: calc(100% - 0px);
    }
    .h1{
        font-size: 1.3rem;
        width: 100%;
    }
    .Social_Icons{
        width: 100%;
        gap: 3%;
    }
}
