.post_container{
    display: flex;
    padding: 80px;
    padding-top: 40px;
    background: rgba(249, 249, 249, 1);
    width: calc(100% - 160px);
    gap: 2%;
}
.BreadCrumbs{
    text-align: start!important;
    justify-content: start !important;
    width: max-content;
}
.post_user_img{
    width: 30px;
    height: 30px;
    object-fit: scale-down;
}
.header{
    color: var(--red);
}
.post_content{
    display: grid;
    width: 75%;
}

.post_info{
    display: inline-flex;
    gap: 20px;
    padding: 0;
    align-items: center;
}
.post_img{
    width: 100%;
    height: 390px;
    max-height: 390px;
    object-fit: cover;
    border-radius: 15px;
}

.post_p{

}
.post_p_list{
    display: grid;
    grid-gap: 20px;
}
.post_p_lis_li{

}
.post_ad_side{
    width: 35%;
}
.ad_banner{
    background: var(--red);
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    padding-top: 5px;
}
.ad_card_header{

}
.download_section{
    margin-top: 10px!important;
    margin: auto;
    margin-bottom: 70px;
    display: flex;
    justify-content: center;
}
.ad_card_p{
    color: rgba(255, 255, 255, 0.67);
}
.ad_card_small_header{
    text-align: start;
    font-weight: bold;
    text-align: center;
}
.small_cards{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 3%;
    margin-top: 10px;
}
.small_card{
    background: rgba(255, 255, 255, 0.13);
    padding: 20px;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: bolder;
    min-width: 18%;
    text-align: center;
    justify-content: center;
}

.small_card_number{
    font-size: 1.2rem;
    font-family: fangsong;
}

.small_card_text{
    
}

.ad_download_img{
    width: 35%;
    margin: 15px;
    cursor: pointer; 
}
.line_break{
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: -20px;
}
.ad_card_p_border{
    width: 100%;
    height: 1px;
    background: rgba(117, 126, 152, 1);
    display: block;
    align-self: center;
    margin: 10px;
}

#ad_card_btn{
    height: 50px;
    width: 50%;
    background: rgba(10, 128, 106, 1);
    color: white;
    border: none;
    font-weight: bolder;
}
.header_secondary{
    font-size: 1.5rem;
    margin-top: 50px;
}
.popular_news_container{

}

.popular_news_card{
    display: flex;
    gap: 10px;
    position: relative;
    padding: 5px;
    border: solid 1px rgba(224, 224, 224, 1);
    border-radius: 5px;
    cursor: pointer;
}
.image_wrapper{
    position: relative;
    display: grid;
    height: 100px;
    width: max-content;
}
.popular_news_img{
    width: auto;
    height: inherit;
}
.text_over_image{
    position: relative;
    bottom: 3px;
    top: -30px;
    color: white;
    font-size: 0.8rem;
    text-align: center;
    margin: 2px;
    width: max-content;
    font-weight: bold;
}
.popular_news_card_texts{
    width: 65%;
}
.popular_news_card_title{
    color: var(--red);
    font-weight: bold;
    text-decoration: underline;
    font-size: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.popular_news_card_date{
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    color: rgba(0, 0, 0, 0.42);
    width: max-content;
    margin-top: 10px;
}
#ul{
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0;
}

.bottom_content{
    background: #f9f9f9;
    padding: 70px;
    padding-top: 0;
}

.hashtags {
    display: flex;
    flex-wrap: wrap;
  }
  
.hashtag {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 15px;
    margin: 5px;
    font-size: 0.9em;
    border: solid 1px #dfdfdf;
    color: rgba(117, 126, 152, 1);
    text-align: center;
}
  
.h3{

}
.social_links{
    display: flex;
    align-items: center;
}

.social_img{
    width: 30px;
    height: 30px;
    margin: 10px;
    cursor: pointer;
}

.h1{
    color: var(--red);
}
@media screen and (max-width:1300px){

    .popular_news_card_texts {
        width: 130%;
    }
}
@media screen and (max-width:1100px){


    .bottom_content {
        padding: 10px;
    }
    .post_container {
        display: block;
        padding: 15px;
        background: rgba(249, 249, 249, 1);
        width: calc(100% - 30px);
        gap: 2%;
    }
    .popular_news_card{
        width: calc(100% - 20px);
    }
    .post_content {
        width: 100%;
    }
    .post_ad_side {
        width: 100%;
    }
    .ad_banner {
        padding: 20px;
    }
    .small_card {
        padding: 30px;
    }
    .small_card_number {
        font-size: 1.2rem;
    }
    .small_card_text{
        font-size: 1rem;
    }
    .header {
        font-size: 1.2rem;
    }
    .ad_card_header {
        font-size: 1rem;
    }
    #ad_card_btn {
        height: 40px;
        width: 80%;
    }
    .header_secondary {
        font-size: 1.2rem;
        margin-top: 30px;
    }
}

@media screen and (max-width:660px){

}

@media screen and (max-width:440px){
    .post_info {
        font-size: 0.75rem;
    }
    .popular_news_card_title {
        font-size: 0.95rem;
    }
    .popular_news_card_date {
        font-size: 0.75rem;
    }
    .small_card {
        padding: 10px;
    }
    .small_card_number {
        font-size: 1rem;
    }
    .small_card_text{
        font-size: 0.85rem;
    }
}