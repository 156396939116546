.image_h_line{
    width: 90%;
    margin: auto;
    display: block;
}
.paper_header{
    width: max-content;
    text-align: center;
    margin: auto;
    align-content: center;
    color: var(--red);
    margin-top: 10px;
    font-weight: bold;
    position: relative;
    transform: scale(.9);
}
.wave{
    margin-bottom: -10px;
    margin-top: -10px;
}

.header_text{
    padding: 0;
    position: absolute;
    margin: 18% auto;
    width: 103%;
    font-size: 1.75rem;
    text-transform: capitalize;
}

.content_1{
    display: flex;
    margin-top: 100px;
}

.cards{
    display: grid;
}

.card{
    text-align: center;
    width: 80%;
    margin: auto 10%;
    position: relative;
}
.card_header{
    color: rgba(136, 5, 13, 1);
}
.card_p{
    line-height: 18px;
}
.split_img{
    width: 200px;
    object-fit: contain;
}

.content_2{
    display: block;
    background: #d5b495;
}

.content_2_p{
    color: rgba(136, 5, 13, 1);
    width: 60%;
    margin: auto 20%;
    line-height: 35px;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 75px;
    margin-top: 30px;
}

.content_3{
    width: 75%;
    margin: auto;
    gap: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.content_4{
    display: block;
    background: #d5b495;
}
.content_4_flex{
    display: flex;
    margin: auto;
    max-width: 80%;
}
.bg{
    width: 100%;
}

.flex_card{
    background-image: url(../../assets/about/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    display: flex;
    align-items: center;
    gap: 30px;
    width: auto;
    padding: 50px;
    border-radius: 50px;
}
.flex_card_flipped{
    background-image: url(../../assets/about/bg_flip.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    align-items: center;
    gap: 30px;
    width: auto;
    padding: 50px;
    border-radius: 50px;
}
.flex_card_block_side{
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 2.5rem;
    color: #88050d;
    font-weight: 500;
    width: max-content;
}
.flex_card_icon{
    width: 140px;
    margin: auto;
    object-fit: scale-down;
    height: 140px;
}
.flex_card_header{
    width: max-content;
}
.flex_card_p{
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    line-height: 30px;
    display: grid;
    gap: 10px;
}
.grid_img{
    width: 200px;
    object-fit: contain;
}

.content_5{
    text-align-last: center;
    margin-bottom: 50px;
}
.centerlize{
    width: 80%;
    margin: auto 10%;
}
.content_5_cards{
    justify-content: center;
}
.content_5_card{
    color: var(--red);
    font-family: 'JosefinSans';
    text-align: center;
}
.card_under_p{
    margin-top: 5px;
    text-align: center;
}
.icon{

}



.categories_section{
    text-align-last: center;
    margin-bottom: 50px;
    text-align: -webkit-center;
}

.categories_cards{
    width: 65%;
    justify-content: center;
    margin: auto;
}


.circle_image{
    min-height: 150px;
    max-height: 150px;
    height: 150px!important;
    width: 150px!important;
    object-fit: scale-down;
}

.circle{
    position: relative;
    text-align: center;
    justify-content: center;
    display: flex;
    align-content: center;
    cursor: pointer;
}


.label{
    position: relative;
    color: var(--red);
    width: 80px;
    padding: 8px;
    align-content: center;
    height: 30px;
    line-height: initial;
    font-size: 0.8rem;
    text-align: center;
    position: absolute;
    font-weight: bold;
    margin: 12% auto;
    align-self: center;
    display: table-caption;
}

@media screen and (max-width:1200px) {
    .flex_card_icon {
        width: 100px;
        height: 100px;
    }
    .flex_card_block_side {
        font-size: 2rem;
    }
}
@media screen and (max-width:1000px) {
    .split_img {
       display: none;
    }
    .content_1 {
        display: block;
        margin-top: 0px;
    }
    .content_2_p {
        width: 80%;
        margin: auto 10%;
        margin-bottom: 75px!important;
        margin-top: 30px!important;
    }
   
    .content_3 {
        width: 95%;
    }
    .icon {
        width: 70px;
    }
    .categories_cards {
        width: 100%;
    }
    .flex_card_p {
        font-size: 1.2rem;
    }
}
@media screen and (max-width:800px) {
    .flex_card_block_side {
        font-size: 1.5rem;
    }
    .flex_card_icon {
        width: 75px;
        height: 75px;
    }
    .flex_card {
        padding: 50px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .flex_card_flipped {
        padding: 50px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .grid_img{
        display: none;
    }
    .content_4_flex {
        display: block;
    }
    .icon {
        width: 50px;
    }
    .flex_card {
        background-image: none;
        display: block;
        justify-content: center;
        text-align: center;
        padding-bottom: 0;
        padding-top: 0;
    }
    .flex_card_block_side {
        margin: auto;
        margin-bottom: 10px;
        background: url(../../assets/about/bg.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        width: 100%;
        align-items: center;
        order: 1;
    }
    .flex_card_flipped {
        background-image: none;
        display: flex;
        flex-flow: column;
        padding-bottom: 0;
        padding-top: 0;
    }
    .flex_card_p {
        font-size: 1.2rem;
        padding-bottom: 50px;
        order: 2;
    }
    .flex_card_icon {
        position: relative;
        top: -8px;
    }
    .flex_card_header {
        position: relative;
        top: -10px;
    }
}

@media screen and (max-width:650px) {
    .circle_image {
        min-height: 120px;
        max-height: 120px;
        height: 120px!important;
        width: 120px!important;
        object-fit: scale-down;
    }
}

@media screen and (max-width:550px) {
    .circle_image {
        min-height: 100px;
        max-height: 100px;
        height: 100px!important;
        width: 100px!important;
        object-fit: scale-down;
    }
    .label {
        width: 60px;
        padding: 8px;
        height: 40px;
        font-size: 0.8rem;
        font-weight: 500;
        margin: 12% auto;
    }
}
@media screen and (max-width:400px) {

    .paper_header {
        width: 100%;
    }
    .header_text {
        font-size: 1.5rem;
        width: 100%;
    }
}