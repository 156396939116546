/*-------------------------------------------------------  N a v b a r  ------------------------------------------*/
.Navbar{
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 999;
    top: 0;
    background: white;
    box-shadow: 0px -5px 13px 0px #a0a0a0;
    z-index: 99;
    padding-left: 2%;
    padding-right: 2%;
}


.Navbar_li{
    color: var(--grey);
    padding: 12px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.1rem;
    display: flex;
    position: relative;
    z-index: 9;
    align-items: center;
    width: max-content;
    justify-content: center;
}
.Navbar_li_active{
    color: var(--red);
    font-weight: bold;
    padding: 12px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.1rem;
    display: flex;
    position: relative;
    z-index: 9;
    align-items: center;
    width: max-content;
    justify-content: center;
}
.wrapping_content{
    display: flex;
    gap: 15px;
}
.Image_Container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Routes_Container{
    background: linear-gradient(46deg,#ffd8d878,#ea8c001f,#0a806a2e);
    border-radius: 50px;
    box-shadow: inset 0 0 5px 2px #d9d9d9;
    display: flex;
    height: 50px;
    justify-content: center;
    position: relative;
    width: max-content;
    padding-right: 8px;
    padding-left: 8px;
    margin: auto;
}
.Control_Container{
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
}
.Navbar_logo{
    width: 7rem;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}
.Download_App{
    font-weight: bold;
    color: var(--red);
    font-size: 1rem;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}
#Register_Btn{
    background: var(--red);
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
}

#ShopNow_Btn{
    background: var(--green);
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.1rem;
}
.indicator {
    position: absolute;
    z-index: 0;
    height: 30px;
    background-color: #7b7b7b3d;
    top: calc(100% - 40px);
    transition: all 0.3s ease-in-out;
    /* transform: scaleX(0); */
    width: 0;
    opacity: 0;
    border-radius: 35px;
}
/* 
.Control_Container {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-around;
} */


@media screen  and (max-width:1280px){
    .Control_Container {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

@media screen  and (max-width:1250px){
    .Routes_Container{
        display: none;
    }
    .Navbar {
        justify-content: space-between;
    }
    .Control_Container {
        width: 100%;
        justify-content: end;
    }
}

@media screen  and (max-width:500px){
    .Navbar_logo{
        width: 5rem;
    }
    .Download_App {
        font-size: 0.75rem;
    }
    #Register_Btn {
        font-size: 0.85rem;
        margin-left: 2px; 
        margin-right: 2px;
        padding-left: 5px;
        padding-right: 5px;
    }
    #ShopNow_Btn {
        font-size: 0.85rem;
        margin-left: 2px; 
        margin-right: 2px;
        padding-left: 8px;
        padding-right: 8px;
    }
    #LangBadge_BTN {
        padding: 0;
        width: 30px;
    }
}
@media screen  and (max-width:375px){

    .Navbar_logo {
        width: 4.3rem;
    }
    #LangBadge_BTN {
        padding: 0!important;
    }
    .Navbar {
        padding-left: 0%; 
        padding-right: 0%;
    }
    #Download_BTN{
        height: 30px;
    }
    .phone_icon {
        font-size: 1rem;
    }
    #Register_Btn, #ShopNow_Btn {
        font-size: .65rem;
        margin-left: 1px;
        margin-right: 1px;
        height: 30px;
    }
}