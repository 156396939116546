.section{
    text-align: center;
    text-align: -webkit-center;
    justify-content: center;
    background: white;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -30px;
    margin-bottom: 60px;
    border-radius: 145px;
    margin-left: 10%;
    margin-right: 10%;
}

.slider{
    margin-top: 20px;
    margin-bottom: -10px;
}

.img{
    min-height: 110px;
    max-height: 110px;
}

.label{
    position: relative;
    top: 7px;
    color: #5b5b5b;
}
#list_btn{
    margin-top: 40px;
    margin-bottom: -40px;
    width: 15em;
    height: 40px;
    font-size: 1rem;
    box-shadow: 0px 5px 9px #ffffff;
    border: solid 1px rgb(197, 197, 197)!important;
    background: var(--red)!important;
    color: white!important;
}
#list_btn:hover{
    background: var(--green)!important;
     box-shadow: 0px 5px 9px #c6c6c6; 
     color:rgb(255, 255, 255)!important;
     border: solid 1px var(--green)!important;
}
@media screen and (max-width:1100px) {
    .section{
        margin-left: 1%;
        margin-right: 1%;
        padding-left: 0;
        padding-right: 0;
    }
    .img{
        width: 90px;
    }
}
@media screen and (max-width:800px) {
    .img{
        width: 70px;
        min-height: 70px;
        max-height: 70px;
    }
    .label{
        font-size: 0.8rem;
    }
}
@media screen and (max-width:580px) {
    .img{
        width: 50px;
        min-height: 50px;
        max-height: 50px;
    }
    .label{
        font-size: 0.7rem;
    }
}