.section{
    margin: auto 10%;
    margin-bottom: 50px;
    display: flex;
    color: white;
    border-bottom: solid 1px #222222;
    border-radius: 12px;
}
.block_content{
    display: block;
    width: 100%;
    padding: 50px;
    align-self: center;
}
.persons_image{
    display: grid;
    align-self: center;
}
.h1{

}
.h3{
    font-size: 1.5rem;
}
.flex_btns{
    display: flex;
    gap: 30px;
}

.image_store{
    cursor: pointer;
    background: black;
    border-radius: 14px;
    border: solid 1px black;
    transition: all 0.3s ease-in-out;
}
.image_store:hover{
    transform: scale(1.05);
    box-shadow: 0px 14px 28px -10px #800008;
}
.image{
    width: 100%;
    position: relative;
}


@media screen and (max-width:1100px) {
    .section {
        width: 100%;
        margin: 0;
        border-radius: 0px;
    }
    .block_content{
        padding: 20px;
    }
}
@media screen and (max-width:760px) {
    .h1 {
        font-size: 1.6rem;
    }
    .h3{
        font-size: 1.2rem;
        width: 250px;
    }

    .image_store{
        width: 45%;
    }

}
@media screen and (max-width:700px) {
    .block_content {
        width: 100%;
        padding: 15px;
    }
}
@media screen and (max-width:580px) {
    .block_content {
        width: 60%;
        padding: 15px;
    }
    .h1{
        font-size: 1.3rem;
    }
}

@media screen and (max-width:380px) {
    .h1{
        font-size: 1rem;
    }
    .h3{
        font-size: 1rem;
    }
}
