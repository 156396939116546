.section{
    text-align: center;
    text-align: -webkit-center;
    justify-content: center;
    background: white;
    margin-top: -60px;
    margin-bottom: 50px;
}
.s_line{
    object-fit: scale-down;
}
.Header{
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 15px;
}
.h1{
}

.p{
    width: 600px;
    font-size: 2rem;
    font-family: JosefinSans;
    color: #888888;
    font-weight: 300;
}

.h2{
    margin: 0;
    font-size: 1.3rem;
    font-family: 'GE_SS_light';
}


.buttons_container{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 20px;
}

.buttons{
    width: calc(40% - 10px);
    background: var(--red);
    border-radius: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 20px!important;
    display: flex;
    gap: 25px;
    margin: auto 30%;
}
.buttons_radio{
    width: max-content;
    border-radius: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    gap: 25px;
    margin-top: 10px!important;
    margin-bottom: 30px!important;
    margin: auto;
    cursor: pointer;
}
.radio_box{
    display: flex;
    align-self: center;
    align-items: center;
    color: #88050d;
    gap: 5px;
    cursor: pointer;
}

.radio_input {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    border: 0.24rem solid #00000066;
    border-radius: 50%;
    width: 1.35rem;
    height: 1.38rem;
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-right: 8px;
    top: -3px;
}



.radio_input:checked {
    background-color: #fff; /* Ensure the background is white */
    border-color: #8B0000; /* Dark red border color when selected */
}

.radio_input:checked::after {
    content: '';
    display: inline-block;
    width: 12px; /* Size of the inner dot */
    height: 12px;
    background-color: #8B0000; /* Dark red color for the inner dot */
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
}


.button{
    border-radius: 30px;
    display: inline-flex;
    align-self: center;
    align-items: center;
    border: none;
    width: 100%;
    height: 35px;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background: rgba(96, 25, 25, 0) !important;
    font-family: 'GE_SS_light';
    padding-top: 8px;
}
.button_active{
    border-radius: 30px;
    display: inline-flex;
    align-self: center;
    border: none;
    width: 100%;
    height: 35px;
    cursor: pointer;
    align-items: center;
    color: var(--red)!important;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    background: rgb(255, 255, 255)!important;
    font-family: 'GE_SS_light';
    padding-top: 8px;
}
.button:hover{
    color: white!important;
    background: rgb(96, 25, 25) !important;
}
#Button{
    width: 200px;
    background: white;
    color: var(--red);
}
.packages_container{
    background: rgba(136, 5, 13, 0.04);
}
.flex_cards{
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: center;
    margin: auto 5%;
}
.cards{
    display: flex;
    margin: 20px;
    margin-top: 30px;
    gap: 15px;
    width: calc(100% - 40px);
}
.single_card{
    width: 25%;
    margin: auto 37.5%;
    display: flex;
    justify-content: center;
}
.card{
    background: white;
    border-radius: 55px;
    padding: 10px;
    width: 100%;
    border: solid 1px grey;
    transform: scale(.9);
    transition: all 0.3s ease-in-out;
}
.popular{
    justify-content: center;
    text-align: center;
    position: absolute;
    top: -39px;
    margin: auto 22%;
    width: 50%;
    background: #88050d;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    display: flex;
    align-items: center;
}
.popular_icon{
    font-size: 1.7rem;
}
.card:hover{
    transform: scale(0.93);
}
.pricing_content{
    border-bottom: solid 2px #6262622b;
    padding-bottom: 20px;
}
.commisions_content{
    justify-content: space-evenly;
    display: flex;
    text-align: center;
    border-bottom: solid 1px #6262622b;
    padding-bottom: 10px;
    margin-bottom: 23px;
}
.commisions_content_li{

}
.commisions_content_li_p{
    background: #906d29bf;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    padding-top: 13px;
    width: 120px;
    text-align: center;
    color: #ffeed5;
    justify-content: center;
}
.commisions_content_li_price{
    font-size: 1.2rem;
}
.flex_pricing_div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.main_price{
    font-size: 2rem;
    color: var(--red);
    margin-bottom: 10px;
    margin-top: 10px;
}
#subscribe_btn{
    border: none;
    color: white;
    background: var(--green);
    width: fit-content;
    white-space: wrap;
    font-family: 'GE_SS_light';
    height: 55px;
    font-size: 1rem;
    font-weight: bolder;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
#subscribe_btn:hover{
    transform: translateY(-5px);
    background: var(--red);
    box-shadow: 0px 10px 8px -4px #adadad;
    color: white;
    /* border: solid 1px var(--green); */
}
.month{
    font-size: 1.2rem;
    margin: 5px;
}

#save_btn{
    background: var(--red);
    color: white;
    border: navajowhite;
    border-radius: 50px;
}
.hash_price{
    text-decoration: line-through;
}
.card_h1{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.6rem;
    font-weight: bolder;
    text-transform: capitalize;
}

.card_p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 70px;
    height: 70px;
    max-height: 70px;
}

.Card_Btn{
    border: none;
    background: transparent;
    color: var(--red);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    box-shadow: none;
}
.Card_Btn:hover{
    color: black!important;
}
.Best_Btn{
    background: var(--green) !important;
}
.Best_Btn:hover{
    background: white !important;
    color: var(--green)!important;
}
.features_container{
    text-align: start;
    margin: auto;
    padding: 20px;
}
.features_container_home{
    max-height: 350px;
    overflow: hidden;
    margin: auto;
}

.feature_li{
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight: normal;
    font-size: 0.9rem;
}


#Best{
    border: solid 3px var(--green);
    background: var(--paige);
    font-weight: bolder;
    transition: all 0.3s ease-in-out;
}

.best_selling{
    position: relative;
    top: -22px;
    background: var(--green);
    color: white;
    width: max-content;
    padding: 4px;
    border-radius: 35px;
    width: 40%;
    font-weight: bolder;
}

.image_h_line{
    width: 90%;
}
.wave{
    margin-top: -10px;
    margin-bottom: -10px;
    width: 100%;
}
.arrow_vector_image{
    margin: auto 70px;
}

@media screen and (min-width:1850px) {
    .single_card{
        width: 20%;
        margin: auto 40%;
        display: flex;
        justify-content: center;
    }
}
@media screen and (min-width:2200px) {
    .single_card{
        width: 15%;
        margin: auto 40%;
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width:1500px) {
    .card{
        width: 100%;
    }
    #Best{
        width: 100%;
    }
}
@media screen and (max-width:1100px) {
    .section {
        margin: 0px;
    }
    .single_card{
        width: 60vw;
        margin: auto;
    }
}
@media screen and (max-width:1024px) {
    .card{
        width: 80%;
    }
    #Best{
        width: 80%;
    }
    .features_container_home{
        max-height: 500px;
    }
}
@media screen and (max-width:950px) {
    .p{    
        width: 80%;
        font-size: 2rem;
    }
    
    .cards{
        display: block;
        margin: 0px;
        margin-top: 0px;
        gap: 0px;
        width: 100%;
    }
    .card {
        width: 95%;
        transform: scale(0.9)!important;
    }
}

@media screen and (max-width:720px) {
    .arrow_vector_image {
        margin: auto 70px;
        width: 25vw;
    }
    .single_card{
        width: 95%;
        margin: auto;
    }
    .flex_cards{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        margin: auto 0%;
    }
    .buttons_container {
        margin-top: 30px!important;
    }
    .buttons{
        width: calc(70% - 10px);
        margin: auto 15%;
        margin-top: 25px!important;
        margin-bottom: -15px!important;
    }
}

@media screen and (max-width:550px) {

    .p{    
        font-size: 1.7rem;
    }
}

@media screen and (max-width:420px) {

    .buttons_container {
        position: relative;
        display: flex;
        margin: 0% auto;
    }
    .commisions_content_li_p {
        width: 100px;
        padding: 8px;
    }
    .commisions_content_li_price {
        font-size: 1rem;
    }
    .feature_li {
        font-size: 1rem;
    }
}
@media screen and (max-width:380px) {
    .commisions_content_li_p {
        width: 90px;
        padding: 6px;
        font-size: 0.8rem;
    }
    .commisions_content_li_price {
        font-size: 0.9rem;
    }
}