.image_h_line{
    width: 90%;
    margin: auto;
    display: block;
}

.header{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2%;
}
.p{
    text-align: center;
    font-size: 1.5rem;
    width: 40vw;
    margin: 2% auto;
    margin-top: -10px;
}
.header_h{
    font-size: 2.5rem;
}
.s_line{
    object-fit: scale-down;
}
.container{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 5%;
    margin: 50% auto;
    margin-top: 0;
    margin-bottom: 100px;
}
.Form{
    width: 50%;
    padding: 30px;
    box-shadow: 0px 0px 8px #ededed;
    border-radius: 8px;
}
.clientName_div{
    display: flex;
    width: 100%;
    gap: 5%;
}
.Input_info{
    display: block;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}
.Label{
    font-weight: bolder;
    color: var(--red);
    margin: 8px;
}
.Input{
    height: 45px;
    margin-left: 5px;
    margin-right: 5px;
}
.Data_Container{
    width: 30%;
}
.Data{
    width: calc(100% - 40px);
    text-align: start;
    height: max-content;
    box-shadow: 0px 0px 8px #e0e0e0;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.Map{
    padding: 20px;
    text-align: center;
    background: rgba(234, 234, 234, 1);
    margin-top: 10px;
    height: 52%;
    border-radius: 8px;
}
.map{
    margin: 22% auto;
    font-size: 2rem;
    color: var(--grey);
}
.Data_header{
    font-size: 2.5rem;
    color: var(--grey);
}
.header{

}
.line{

}
.Social_Icons{
    border: solid 2px #f1f1f1;
    padding: 5px;
    margin-top: 10px;
    border-radius: 45px;
    width: max-content;
    display: flex;
    padding-top: 0;
    background: #c6c3c340;
}
.social_icon{
    width: 16px;
    object-fit: contain;
    height: 16px;
    transition: all 0.2s ease-in-out;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    top: 6px;
    border-radius: 0px;
    padding: 5px;
    color: rgb(0, 0, 0);
}
.social_icon:hover{
    color: #0a806a;
}


#send_btn{
    width: 100%;
    height: 45px;
    background: var(--red);
    color: white;
    margin-top: 20px;
}
.error{
    color: red;
    margin: 8px;
}
@media screen  and (max-width:950px){
    .container{
        display: block;
        width: 100%;
        gap: 0%;
        margin: 0%;
    }
    .Form {
        width: calc(100% - 70px);
        padding: 30px;
        box-shadow: none;
    }
    .Data_Container {
        width: 100%;
    }
    .Data {
        box-shadow: none;
    }
    .Map {
        padding: 20px;
        text-align: center;
        background: #f2f2f2;
        margin-top: 10px;
        height: 52%;
        box-shadow: none;
        border-radius: 0px;
    }
}
@media screen  and (max-width:1100px){
    .p {
        width: 90%;
    }
    .Data_header {
        font-size: 2rem;
    }
}
@media screen  and (max-width:500px){
    .h1{font-size: 1.59rem;}
    .p{
        font-size: 1.2rem;
    }
    .Data_header {
        font-size: 1.8rem;
    }
}
@media screen  and (max-width:450px){
    .h1{
        font-size: 1.5em;
        text-align: center;
    }
    .Header{
        transform: scale(0.9)!important;
    }
    .h1{
        width: 90%!important;
    }
    .s_line{
        object-fit: scale-down;
        width: 5%;
        transform: scale(5);
    }
}