
.Section{
    padding: 100px;
    padding-top: 0px;
}
.catalog{
    width: 40vw;
    height: auto;
    object-fit: contain;
    margin: auto;
    margin-top: 3rem;
}

.Row{
    display: flex;
}
.Row_2_content{
    justify-content: center;
    text-align: center; 
}
.Row_2{
    display: flex;
}
.Column{
width: 50%;
align-self: center;
}
.header{
    display: flex;
    gap: 1vw;
    align-items: center;
}
.s_line{
    height: fit-content;
}
.h3{
    font-size: 1.2rem;
    font-weight: bolder;
    
}
.p{
    font-size: 1.2rem;
}
.line{
    display: flex;
    align-items: center;
    gap: 0.5vw;
    border-radius: 10px;
    padding: 10px;
    margin: -10px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.line_text{

}
.line_text_bold{
    font-weight: bolder;
    
    color: var(--brown);
}
.icon{
    height: 1.5vw;
    width: 1.5vw;
}
#Line_1{
    background: rgba(223, 144, 25, 0.1);
}
#Line_2{
    background:  rgba(158, 158, 98, 0.1);
}
#Line_3{
    background: rgba(136, 5, 13, 0.05);
}

.image{

}

.Card{
    text-align: center;
    background: url(../../../../assets/imgs/coverf.png);
    background-repeat: repeat;
    width: 100%;
    margin: 45px;
    border-radius: 20px;
    padding: 30px;
    transition: all 0.3s ease-in-out;
    filter: saturate(1.4);
}
.Card_header{
    font-weight: bolder;
    
}


#Button{
    height: 50px;
    width: 50%;
    background: var(--red);
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin: 2% auto;
    margin-top: 10px;
}

#Button:hover{
    box-shadow: 0px 8px 8px grey;
    transform: translateY(-3px);
}

.pdf_buttons_flex{
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
}
.file_button{
    height: 50px!important;
    display: flex!important;
    align-items: center!important;
    width: 40%!important;
    justify-content: center!important;
    color: white!important;
    border: none!important;
    gap: 5px;
    box-shadow: 0px 0px 8px rgb(255, 255, 255)!important;
    transition: all 0.3s ease-in-out!important;
}

.file_button:hover{
    color: white!important;
    border: none!important;
    box-shadow: 0px 10px 8px #88050d47 !important;
}

.company_button{
    background: var(--red)!important;
}
.seller_button{
    background: var(--green)!important;
    box-shadow: 0px 0px 8px rgb(255, 255, 255)!important;
    transition: all 0.3s ease-in-out!important;
}
.seller_button:hover{
    border: none!important;
    box-shadow: 0px 10px 8px rgb(97 146 88 / 41%) !important;
}

.btn_img{
    width: 1.5rem;
}
@media screen and (max-width:1450px) {
    .catalog{
        margin-top: 8rem;
    }
}
@media screen and (max-width:1200px) {
    .catalog{
        width: 45%;
        margin-top: 12rem;
    }
}
@media screen and (max-width:1100px) {
    .Column{ width: 90%;  margin: auto;}
    .Row { display: block; width: 100%; }
    .header { justify-content: center; } 
    .h3 { text-align: center;font-size: 1.5rem; }
    .p{font-size: 1.4rem; text-align: justify;white-space: break-spaces;}
    .Section{ padding: 20px; margin-top: -50px; margin-bottom: 60px;}
    .line{ width: 100%; }
    .catalog {
        width: 94%;
        height: auto;
        margin: auto 3%;
        margin-bottom: 30px;
        margin-top: 10px;
    }
    .line_text{
        font-size: 1.1rem;
        display: flow-root;
        width: 100%;
    }
    .line_text_bold{ text-align: start; margin-bottom: 20px }
    .icon {
        height: 2rem;
        width: 2rem;
        margin-left: 5px;
        margin-right: 5px;
    }
    .Row_2 { display: flex; }
    .Card {
        margin: 5px;
        margin-bottom: 20px;
        padding: 10px;
        width: calc(100% - 30px);
    }
    #Button {
        width: 60%;
        margin-top: 22px;
        margin-bottom: 22px;
    }
    .pdf_buttons_flex {
        margin-top: 50px;
        margin-bottom: 30px;
    }
}
@media screen and (max-width:770px) {
    .Row_2 {     
        display: grid;
        gap: 20px; 
    }
}
@media screen and (max-width:590px) {
    .line_text_bold{  margin-bottom: 10px}
    .h3{font-size: 1rem;}
    .p{font-size: 1.2rem;}
    .line_text{font-size: 1rem;line-height: 1.2rem;}
    .icon {
        margin-top: 0px;
    }
    .image{width: 25%; margin-bottom: -20px;}
    #message_image{width: 20%;}
    .pdf_buttons_flex {
        margin-top: 40px;
        margin-bottom: 30px;
    }
}
@media screen  and (max-width:500px){
    .Header{
        transform: scale(0.9)!important;
    }
    .h1{
        width: 80%!important;
    }
    .s_line{
        object-fit: scale-down;
        width: 20%;
        transform: scale(1.2);
    }
    .file_button{
        width: 80%!important;
    }
}

@media screen  and (max-width:450px){
    .line_text {
        font-size: 0.85rem;
        line-height: 1.2rem;
    }
    .file_button {
        font-size: 0.75rem;
    }
    .btn_img {
        width: 1rem;
    }
    .Card_header {
        font-size: 1.4rem;
        margin: 20px;
    }
}