.page_container{
    padding: 140px;
    padding-top: 50px;
}
.content{

}
.card_image{
    width: 100%;
}
.h{
    
}
.header{
    font-size: 1rem;
    font-weight: bold;
}
.p{

}

@media screen and (max-width:1100px){
    .page_container{
        padding: 40px;
        padding-top: 20px;
    }
}